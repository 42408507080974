.widget-testimonials {

  .slick-prev,
  .slick-next {
    top: -30px;
    width: 20px;
    height: 20px;

    &:before {
      width: 13px;
      height: 13px;
    }

  }

  .slick-prev {
    left: 180px;
  }

  blockquote {
    border: none;
    margin: 0;
    padding: 0;
    width: 100%;
    left: 0;
  }

  .slick-slider,
  .testimonial {
    margin-top: 0;
  }

}
