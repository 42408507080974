/*

Animation speeds

*/
$speed-fast: 0.35s;
$speed-slow: 0.7s;


/*

Animations

*/
@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

.animated {
  animation-duration: $speed-fast;
}
