.officer-name,
.officer-title,
.officer-seperator {
  margin: 0;
  font-size: $font-xl;
}

.officer-name {
  font-weight: bold;
}

.officer-seperator {
  padding: 0 $space-xxxs;
}

.officer-description {
  font-family: $font-secondary;
}

.officer {
  padding: $space-xl*2 0;
  border-top: 1px solid color(grey-xlight);

  @include respond-to(600, 'max') {
    margin: 0;
  }

  .officer-image {

    @include respond-to(600, 'max') {
      max-width: 200px;
      margin: 0 auto;
    }

  }

  &:nth-child(even) {
    flex-direction: row-reverse;

    @include respond-to(600, 'max') {
      flex-direction: row;
    }

  }

  &:nth-child(odd) {
    .officer-meta,
    .officer-description {
      padding-left: $space-m;

      @include respond-to(600, 'max') {
        padding-left: 0;
      }

    }
  }

  &:last-child {
    padding-bottom: 0;
  }

}

.officer-info {
  padding-left: 0;
  flex: 1;

  @include respond-to(600, 'max') {
    padding: $space-m;
  }

}

.officer-meta {
  display: flex;
  flex-flow: row wrap;
}
