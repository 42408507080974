@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?568okp');
  src:  url('../fonts/icomoon.eot?568okp#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?568okp') format('truetype'),
    url('../fonts/icomoon.woff?568okp') format('woff'),
    url('../fonts/icomoon.svg?568okp#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-double-left:before {
  content: "\e900";
}
.icon-arrow-double-right:before {
  content: "\e901";
}
.icon-arrow-single-left:before {
  content: "\e902";
}
.icon-arrow-single-right:before {
  content: "\e903";
}
.icon-doc:before {
  content: "\e904";
}
.icon-download:before {
  content: "\e905";
}
.icon-dwg:before {
  content: "\e906";
}
.icon-link:before {
  content: "\e907";
}
.icon-protractor:before {
  content: "\e908";
}
.icon-search:before {
  content: "\e909";
}

.icon-sketchboard:before,
.icon-sketchpad:before {
  content: "\e90a";
}

h1, h2, h3, h4, h5, h6 {
  font-family: $font-primary;
  line-height: 1.1;
  color: color(base);
  text-transform: uppercase;
  margin: 0 0 $space-xl 0;
  font-weight: bold;
  letter-spacing: 0.08em;
  padding-bottom: 0;

  a {
    // text-decoration: none;
    color: color(base);
  }

  + p {
    margin-top: 0.5em;
  }

}

h1 {
  font-size: $font-xxxl;

  @include respond-to(600, 'max') {
    font-size: $font-xl;
  }
}

h2 {
  font-size: $font-xl;
}

h3, h4 {
  font-size: $font-l;
  // font-weight: normal;
  @include respond-to(500, 'max') {
    font-size: $font-xxxl;
  }

}

h5, h6 {
  font-size: $font-m;
}

a {
  color: color(base);
  text-decoration: underline;
  font-weight: 100;

  &:hover {
    cursor: pointer;
    color: color(primary);
    // opacity: $opacity-soft;
  }

}

p {
  line-height: 1.8;
  margin-top: $space-m;
  margin-bottom: $space-m*2;
}


blockquote {
  padding: 0 0 0 $space-l;
  font-style: italic;
  border-left: 3px solid color(base);
  margin: 2.5em 0 2.5em 1.5em;
  position: relative;
  width: 120%;
  left: -14%;
  font-size: $font-l;

  @include respond-to(500, 'max') {
    margin-left: 0;
  }
}
