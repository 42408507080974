.drawer-answer {
  font-family: $font-secondary;
  margin-left: 0;
  margin-top: 0;
  padding: 0;
}

.drawer-question {
  text-transform: uppercase;
  font-size: $font-xl;
}

.drawers-group {
  margin-top: $space-xxxxs;
}

.drawers-heading {
  font-size: $font-xl;
  background: color(off-white);
  color: color(base);
  padding: $space-m $space-xl;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 5px;
  text-transform: none;
  font-weight: normal;

  @include smoothTransition();

  &:hover {
    background: darken( color(off-white), 5%);
    cursor: pointer;
  }

  .svg-inline--fa {
    float: right;
  }

}

.component-drawers {
  margin-top: 0;

  .drawers {
    display: none;
    margin-top: 0;
    padding: $space-l $space-l*2;
    margin-bottom: $space-l*2;
    background: color(grey-xxlight);
  }

}

.drawers-content {
  display: none;
  font-family: $font-secondary;
  margin-top: $space-s;
  margin-bottom: $space-xxxl;

  p:first-of-type {
    margin-top: 0;
  }

}
