$ms-base: 1em;
$ms-ratio: 1.1;

$font-xxxs:   ms(-4);
$font-xxs:    ms(-3);
$font-xs:     ms(-2);
$font-s:      ms(-1);
$font-m:      ms(0);
$font-l:      ms(1);
$font-xl:     ms(2);
$font-xxl:    ms(4);
$font-xxxl:   ms(5);
$font-xxxxl:  ms(6);
$font-xxxxxl: ms(7);

$font-primary: 'Lato', sans-serif;
$font-secondary: 'Libre Baskerville', serif;
