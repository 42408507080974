$space-xxxxxxxxs:             ms(-10);
$space-xxxxxxxs:              ms(-9);
$space-xxxxxxs:               ms(-8);
$space-xxxxxs:                ms(-7);
$space-xxxxs:                 ms(-6);
$space-xxxs:                  ms(-5);
$space-xxs:                   ms(-4);
$space-xs:                    ms(-3);
$space-s:                     ms(-2);
$space-m:                     ms(0);
$space-l:                     ms(1);
$space-xl:                    ms(2);
$space-xxl:                   ms(3);
$space-xxxl:                  ms(4);

$margin:                      ms(2);
$gutter:                      ms(1);

$max-width:                   85%;
$container-width:             85%;
$max-width-large:             1000px;
$max-width-xlarge:            1140px;
$content-width-narrow:        800px;
$content-width-extra-narrow:  600px;

$form-padding:                $space-xs;
