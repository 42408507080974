.footer {
  background: color(primary);
  margin: 0;
  font-size: $font-s;
  padding-top: $space-xl*3;

  @include respond-to(1100, 'max') {
    padding-top: $space-xl*2;
  }

}

.footer-section {
  background-color: color(primary);
  margin: 0;
  padding: $space-s 0;

  .contain {
    width: 100%;
  }

}

.footer-middle {
  padding-top: $space-m;
  padding-bottom: $space-xl*2;

  > .contain {
    @include respond-to(900, 'max') {
      display: block;
    }
  }

  @include respond-to(900, 'max') {
    padding-bottom: 0;
  }

}

.footer-bottom {
  overflow: hidden;
  border-top: 1px solid color(grey-dark);
  color: color(grey-medium);
}

.footer-social {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 290px;
  justify-content: space-between;

  @include respond-to(900, 'max') {
    min-width: 100%;
    width: 100%;
    justify-content: center;
  }

}

.footer-social-link {
  display: block;

  &:hover {
    opacity: $opacity-soft;
  }

}

.footer-tagline {
  color: white;
  font-weight: 100;
  font-size: $font-m;
  padding-left: 16.8%;

  @include respond-to(900, 'max') {
    display: none;
  }

}

.footer-social-image {
  width: 25px;
  height: 50px;

  @include respond-to(500, 'max') {
    height: 30px;
    width: 22px;
  }

}

.footer-social-item {
  margin-top: 0;
  padding-bottom: 0;

  @include respond-to(900, 'max') {
    padding-right: $space-xl;
    padding-left: $space-xl;
  }

}


.footer-copyright {
  text-align: center;
  font-weight: 100;
  font-size: $font-xs;
}
