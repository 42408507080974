#panel,
#wpadminbar {
   margin-top: 0;
}

#comments {
   display: none;
}

.main {
   width: 100%;
   margin: 0;
   height: 100%;
   justify-content: center;
   padding-bottom: 0;

   @include respond-to(600, 'max') {
      h2:first-of-type,
      h3:first-of-type {
         margin-top: 0;
      }
   }
}

.single-stories {
   .main {
      @include respond-to(800, 'max') {
         margin-top: 0;
      }
   }
}

.wrap.container {
   margin: 0 auto;
   overflow-y: visible !important;

   &[data-heading-in-header='1'] {
      padding-top: $space-xl * 2;
   }

   @include respond-to(800, 'max') {
      margin-top: $space-xxs;
   }
}

.archive,
.blog {
   .wrap.container {
      margin-top: 0;
   }
}

.blog .page-heading {
   font-family: Lato, sans-serif;
   font-size: 42px;
   padding: 0;
   margin: 0;
}

.blog .main,
.single-post .main {
   margin-top: 6em;
   max-width: 920px;
   margin-left: auto;
   margin-right: auto;
   padding: 0;

   .contain-narrow {
      max-width: 100%;
   }

   .entry-title {
      font-weight: bold;
   }
}

.blog {
   .main {
      .entry-title a {
         font-size: 28px;
      }

      .entry-summary > p a {
         display: block !important;
         margin-top: 20px !important;
         width: 160px;
         text-align: center;
         text-transform: uppercase !important;
      }

      .entry-summary,
      article .contain-narrow {
         max-width: 100%;
         font-size: 16px;
      }

      .entry-title {
         margin-bottom: 10px;
      }

      .byline,
      .updated {
         margin-bottom: 20px;
         font-size: 16px;
         line-height: 1;
      }

      .post {
         margin-bottom: 4em;
         max-width: 100%;
         border-bottom: 1px solid #c5c5c5;
         padding-bottom: 1em;
      }

      header {
         .entry-categories {
            display: none;
            margin-top: 0;
         }
      }
   }
}

.has-subnav {
   .main,
   .component {
      @include respond-to(800, 'max') {
         padding: 0 $space-l;
         margin: 0 auto;
      }

      @include respond-to(600, 'max') {
         padding: 0;
      }
   }
}

.slideout-open {
   height: 100%;
   overflow: hidden;
   // -webkit-overflow-scrolling: auto !important;

   body {
      -webkit-overflow-scrolling: auto !important;
      height: 100%;
      overflow: hidden;

      > * {
         overflow: auto;
         height: 100%;
      }
   }

   #panel {
      height: 100%;
      overflow: hidden;
      position: fixed;
      top: 0;
      bottom: 0;
   }

   .component-subnav .toggle-mobile-menu {
      display: none;
   }

   .has-subnav {
      .component,
      .main {
         margin-left: 0;
      }
   }
}

.search.search-results {
   .component,
   .main {
      margin: 0;
   }
}

.component {
}
.component-title {
}

.wp-caption {
   width: 100% !important;
   margin: 0 !important;
}

.wp-caption-text {
   margin-top: 10px;
   font-family: $font-primary;
   color: color(grey-xlight);
   font-weight: 100;
   max-width: 75%;
}
