.carousel-wrapper,
.carousel-slide {
  margin: 0;
  min-height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat;
}

.carousel {
  margin-top: 0;
  width: 100%;
  padding: 0;
  opacity: 0;
  min-height: 500px;

  @include smoothTransition();

  &.slick-slider {
    margin-top: 1.8em;

    @media (max-width: 1000px) {
      margin-top:0 ;
    }

  }

  &.slick-initialized {
    opacity: 1;
  }

  .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0;
  }

}

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: color(grey-dark);
  border-radius: 50%;
  margin: 0;
  z-index: 1;

  &:hover {
    opacity: 0.75;
  }

}

.slick-next {
    right: 25px;
}

.slick-prev {
    left: 25px;
}

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1;
}

.slick-prev:before {
  content: url('../imgs/icon-arrow-prev.svg');
}

.slick-next:before {
  content: url('../imgs/icon-arrow-next.svg');
}

.slick-prev:before,
.slick-next:before {
  background-size: contain;
  line-height: 1;
  opacity: 1;
  color: white;
  font-weight: 300;
  display: block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  width: 26px;
  height: 26px;
  margin: 0 auto;
}

.carousel-slide {
  position: relative;
}

.carousel-slide-content {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 1100px;
  min-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: $space-m*4.4 $space-l*4;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include respond-to(1000, 'max') {
    min-width: 90%;
  }

  @media (max-width: 500px) {
    padding: 3em 2em;
  }

}

.carousel-slide-content,
.carousel-slide-content h1,
.carousel-slide-content h2,
.carousel-slide-content h3,
.carousel-slide-content h4,
.carousel-slide-content h5,
.carousel-slide-content h6 {
  color: white;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  margin-bottom: $space-m;
  line-height: 1;

  &:last-child {
    margin-bottom: 0;
  }

}

.carousel-slide-content p:last-child {
  margin-bottom: 0;
}

.carousel-slide-content .btn {
  font-size: $font-xl;
}


.carousel-slide-content h1 {
  font-size: $font-xl*3;
  margin-bottom: 0.7em;

  @media (max-width: 900px) {
    font-size: $font-xl*2;
  }

  @media (max-width: 500px) {
    font-size: $font-xl*1.3;
  }

}

.carousel-slide-content h2 {
  font-size: $font-s*2;

  @media (max-width: 900px) {
    font-size: $font-xl*1.2;
  }

  @media (max-width: 500px) {
    font-size: $font-l;
  }

}

.carousel-slide-content h3 {
  font-size: $font-xxl;
  white-space: normal;
  font-weight: 700;

  @media (max-width: 500px) {
    font-size: $font-m;
    font-weight: normal;
  }

}
