@charset "UTF-8";
/*!

Author:         Andrew M Robbins
Author URI:     https://simpleblend.net

*/
/*

Breakpoints

*/
/*

Grid Columns

*/
/*

Opacities

*/
/*

Animation speeds

*/
/*

Animations

*/
@keyframes spin {
  100% {
    transform: rotate(360deg); } }

.animated {
  animation-duration: 0.35s; }

/*

Retrieve a selected color from our $colors palette

*/
/*

WooCommerce

*/
.woocommerce .quantity .qty,
.opc_order_review .product-quantity,
.opc_order_review .product-details {
  margin: 0 auto; }

.opc_order_review .product-details {
  text-align: left;
  padding-left: 1em;
  max-width: 400px; }
  @media (max-width: 700px) {
    .opc_order_review .product-details {
      max-width: 300px; } }

.cart-subtotal th,
.order-total th {
  text-align: right; }

@media (max-width: 500px) {
  #order_review .woocommerce-checkout-review-order-table {
    display: block;
    width: 100%; }
    #order_review .woocommerce-checkout-review-order-table .product-remove {
      width: 100%;
      display: block;
      float: none;
      margin: 0;
      text-align: center; }
      #order_review .woocommerce-checkout-review-order-table .product-remove .remove {
        display: block;
        margin: 0 auto; }
    #order_review .woocommerce-checkout-review-order-table thead {
      display: none; }
    #order_review .woocommerce-checkout-review-order-table tbody,
    #order_review .woocommerce-checkout-review-order-table tfoot,
    #order_review .woocommerce-checkout-review-order-table .cart_item {
      display: flex;
      flex-direction: column;
      margin-top: 0; }
    #order_review .woocommerce-checkout-review-order-table tfoot {
      padding-bottom: 1.7em; }
    #order_review .woocommerce-checkout-review-order-table .cart-subtotal,
    #order_review .woocommerce-checkout-review-order-table .order-total {
      margin: 0;
      border: none; }
      #order_review .woocommerce-checkout-review-order-table .cart-subtotal td,
      #order_review .woocommerce-checkout-review-order-table .order-total td {
        border: none; }
      #order_review .woocommerce-checkout-review-order-table .cart-subtotal th,
      #order_review .woocommerce-checkout-review-order-table .order-total th {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 2em;
        border: none; }
    #order_review .woocommerce-checkout-review-order-table .product-details {
      text-align: center;
      display: block;
      width: 100%;
      padding: 0; }
    #order_review .woocommerce-checkout-review-order-table .product-quantity {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 1em; }
    #order_review .woocommerce-checkout-review-order-table .product-total {
      border-top: none;
      border-bottom: 1px solid #ddd;
      padding-bottom: 2em; } }

@media (max-width: 500px) {
  .woocommerce table.shop_table .product-item td:last-child {
    padding-bottom: 2em; } }

.woocommerce table.shop_table td {
  text-align: center; }
  @media (max-width: 500px) {
    .woocommerce table.shop_table td {
      width: 100%;
      max-width: 100%;
      display: block;
      margin-top: 0; } }

@media (max-width: 500px) {
  .woocommerce .product-thumbnail a img {
    max-width: 100px;
    margin: 0 auto; } }

.woocommerce .product-thumbnail a {
  display: block;
  padding: 10px;
  max-width: 70%;
  margin: 0 auto; }
  .woocommerce .product-thumbnail a img {
    position: relative;
    top: 7px;
    left: 10px; }

.woocommerce form .form-row {
  margin: 0 0 18px; }

.place-order {
  background-color: white;
  font-size: 20px; }

.woocommerce-terms-and-conditions-wrapper {
  margin-top: 0; }

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods,
.woocommerce-checkout #payment div.form-row {
  background-color: white;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none; }

form.woocommerce-checkout {
  margin-bottom: 7em; }

#order_review .opc_cart_item td.product-name {
  padding: 2em; }

.product-item .product-thumbnail,
.product-item .product-name,
.product-item .product-price {
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

@media (max-width: 500px) {
  .woocommerce table.shop_table td.product-name,
  .woocommerce table.shop_table td.product-price,
  .woocommerce table.shop_table td.product-quantity {
    border-top: none; } }

/*

WordPress Default Styles

*/
img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

a img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

img.size-full {
  width: 100%; }
  @media (max-width: 600px) {
    img.size-full {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: block; } }

.flexboxtweener .nav-primary {
  position: relative;
  top: -40px; }

.flexboxtweener .header-shrink .nav-primary {
  top: -47px; }

.flexboxtweener .header.row,
.flexboxtweener .header-container,
.flexboxtweener .header-wrapper {
  display: block; }

.flexboxtweener .component-generic .row {
  display: block; }
  .flexboxtweener .component-generic .row [class*="col"] {
    float: left; }

.flexboxtweener .row-center {
  display: block;
  margin: 0 auto; }

.flexboxtweener .col,
.flexboxtweener .l-col {
  display: block;
  width: 100%;
  flex: none; }

.flexboxtweener .nav-mobile {
  display: none; }

.flexboxtweener .l-content-main {
  float: left;
  width: 100%;
  max-width: 100%;
  flex: none;
  height: auto; }
  .flexboxtweener .l-content-main:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener .l-component,
.flexboxtweener .l-wrapper .component,
.flexboxtweener .l-wrapper :only-of-type:last-of-type.component {
  flex: none;
  display: block;
  width: 100%;
  height: auto; }
  .flexboxtweener .l-component:after,
  .flexboxtweener .l-wrapper .component:after,
  .flexboxtweener .l-wrapper :only-of-type:last-of-type.component:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener .l-wrapper,
.flexboxtweener .l-content,
.flexboxtweener .l-content-main {
  display: block !important;
  width: 100%;
  flex: none; }
  .flexboxtweener .l-wrapper:after,
  .flexboxtweener .l-content:after,
  .flexboxtweener .l-content-main:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener [class*="l-box"] {
  display: block;
  flex: none; }

.flexboxtweener .l-col-2 {
  width: calc(100%/2);
  max-width: calc(100%/2); }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-2 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-3 {
  width: calc(100%/3);
  max-width: calc(100%/3); }
  @media (max-width: 900px) {
    .flexboxtweener .l-col-3 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-3 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-4 {
  width: calc(100%/4);
  max-width: calc(100%/4); }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-4 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-4 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-5 {
  width: calc(100%/5);
  max-width: calc(100%/5); }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-5 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-5 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-6 {
  width: calc(100%/6);
  max-width: calc(100%/6); }
  @media (max-width: 1000px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/4);
      max-width: calc(100%/4); } }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/3);
      max-width: calc(100%/3); } }
  @media (max-width: 700px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-7 {
  width: calc(100%/7);
  max-width: calc(100%/7); }
  @media (max-width: 1100px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/5);
      max-width: calc(100%/5); } }
  @media (max-width: 900px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/4);
      max-width: calc(100%/4); } }
  @media (max-width: 700px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/3);
      max-width: calc(100%/3); } }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

*, :after, :before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box; }

html {
  font-family: sans-serif;
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%; }

body {
  margin: 0; }

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
menu,
nav,
section,
summary {
  display: block; }

audio,
canvas,
progress,
video {
  display: inline-block;
  vertical-align: baseline; }

audio:not([controls]) {
  display: none;
  height: 0; }

[hidden],
template {
  display: none; }

a {
  background-color: transparent; }

a:active,
a:hover {
  outline: 0; }

abbr[title] {
  border-bottom: 1px dotted; }

dfn {
  font-style: italic; }

h1, h2, h3, h4, h5, h6 {
  font-weight: inherit; }

mark {
  background: #ff0;
  color: #000; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

img {
  height: auto;
  border: 0;
  max-width: 100%; }

svg:not(:root) {
  overflow: hidden; }

figure {
  margin: 1em 40px; }

hr {
  -moz-box-sizing: content-box;
  box-sizing: content-box;
  height: 0; }

pre {
  overflow: auto; }

code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  font-size: 1em; }

button,
input,
optgroup,
select,
textarea {
  color: inherit;
  font: inherit;
  margin: 0; }

button {
  overflow: visible; }

button,
select {
  text-transform: none; }

button,
html input[type="button"],
input[type="reset"],
input[type="submit"] {
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled],
html input[disabled] {
  cursor: default; }

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0; }

input {
  line-height: normal; }

input[type="checkbox"],
input[type="radio"] {
  box-sizing: border-box;
  padding: 0; }

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  height: auto; }

input[type="search"] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box; }

input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-decoration {
  -webkit-appearance: none; }

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em; }

legend {
  border: 0;
  padding: 0; }

textarea {
  overflow: auto; }

optgroup {
  font-weight: bold; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

td,
th {
  padding: 0; }

/*
  Plugin Overrides
*/
/*

WooCommerce

*/
.woocommerce .quantity .qty,
.opc_order_review .product-quantity,
.opc_order_review .product-details {
  margin: 0 auto; }

.opc_order_review .product-details {
  text-align: left;
  padding-left: 1em;
  max-width: 400px; }
  @media (max-width: 700px) {
    .opc_order_review .product-details {
      max-width: 300px; } }

.cart-subtotal th,
.order-total th {
  text-align: right; }

@media (max-width: 500px) {
  #order_review .woocommerce-checkout-review-order-table {
    display: block;
    width: 100%; }
    #order_review .woocommerce-checkout-review-order-table .product-remove {
      width: 100%;
      display: block;
      float: none;
      margin: 0;
      text-align: center; }
      #order_review .woocommerce-checkout-review-order-table .product-remove .remove {
        display: block;
        margin: 0 auto; }
    #order_review .woocommerce-checkout-review-order-table thead {
      display: none; }
    #order_review .woocommerce-checkout-review-order-table tbody,
    #order_review .woocommerce-checkout-review-order-table tfoot,
    #order_review .woocommerce-checkout-review-order-table .cart_item {
      display: flex;
      flex-direction: column;
      margin-top: 0; }
    #order_review .woocommerce-checkout-review-order-table tfoot {
      padding-bottom: 1.7em; }
    #order_review .woocommerce-checkout-review-order-table .cart-subtotal,
    #order_review .woocommerce-checkout-review-order-table .order-total {
      margin: 0;
      border: none; }
      #order_review .woocommerce-checkout-review-order-table .cart-subtotal td,
      #order_review .woocommerce-checkout-review-order-table .order-total td {
        border: none; }
      #order_review .woocommerce-checkout-review-order-table .cart-subtotal th,
      #order_review .woocommerce-checkout-review-order-table .order-total th {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 2em;
        border: none; }
    #order_review .woocommerce-checkout-review-order-table .product-details {
      text-align: center;
      display: block;
      width: 100%;
      padding: 0; }
    #order_review .woocommerce-checkout-review-order-table .product-quantity {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 1em; }
    #order_review .woocommerce-checkout-review-order-table .product-total {
      border-top: none;
      border-bottom: 1px solid #ddd;
      padding-bottom: 2em; } }

@media (max-width: 500px) {
  .woocommerce table.shop_table .product-item td:last-child {
    padding-bottom: 2em; } }

.woocommerce table.shop_table td {
  text-align: center; }
  @media (max-width: 500px) {
    .woocommerce table.shop_table td {
      width: 100%;
      max-width: 100%;
      display: block;
      margin-top: 0; } }

@media (max-width: 500px) {
  .woocommerce .product-thumbnail a img {
    max-width: 100px;
    margin: 0 auto; } }

.woocommerce .product-thumbnail a {
  display: block;
  padding: 10px;
  max-width: 70%;
  margin: 0 auto; }
  .woocommerce .product-thumbnail a img {
    position: relative;
    top: 7px;
    left: 10px; }

.woocommerce form .form-row {
  margin: 0 0 18px; }

.place-order {
  background-color: white;
  font-size: 20px; }

.woocommerce-terms-and-conditions-wrapper {
  margin-top: 0; }

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods,
.woocommerce-checkout #payment div.form-row {
  background-color: white;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none; }

form.woocommerce-checkout {
  margin-bottom: 7em; }

#order_review .opc_cart_item td.product-name {
  padding: 2em; }

.product-item .product-thumbnail,
.product-item .product-name,
.product-item .product-price {
  border-right: 1px solid rgba(0, 0, 0, 0.1); }

@media (max-width: 500px) {
  .woocommerce table.shop_table td.product-name,
  .woocommerce table.shop_table td.product-price,
  .woocommerce table.shop_table td.product-quantity {
    border-top: none; } }

/*

WordPress Default Styles

*/
img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

a img.alignright {
  float: right;
  margin: 0 0 1em 1em; }

a img.alignleft {
  float: left;
  margin: 0 1em 1em 0; }

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto; }

img.size-full {
  width: 100%; }
  @media (max-width: 600px) {
    img.size-full {
      width: 100%;
      max-width: 100%;
      margin: 0 auto;
      display: block; } }

.flexboxtweener .nav-primary {
  position: relative;
  top: -40px; }

.flexboxtweener .header-shrink .nav-primary {
  top: -47px; }

.flexboxtweener .header.row,
.flexboxtweener .header-container,
.flexboxtweener .header-wrapper {
  display: block; }

.flexboxtweener .component-generic .row {
  display: block; }
  .flexboxtweener .component-generic .row [class*="col"] {
    float: left; }

.flexboxtweener .row-center {
  display: block;
  margin: 0 auto; }

.flexboxtweener .col,
.flexboxtweener .l-col {
  display: block;
  width: 100%;
  flex: none; }

.flexboxtweener .nav-mobile {
  display: none; }

.flexboxtweener .l-content-main {
  float: left;
  width: 100%;
  max-width: 100%;
  flex: none;
  height: auto; }
  .flexboxtweener .l-content-main:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener .l-component,
.flexboxtweener .l-wrapper .component,
.flexboxtweener .l-wrapper :only-of-type:last-of-type.component {
  flex: none;
  display: block;
  width: 100%;
  height: auto; }
  .flexboxtweener .l-component:after,
  .flexboxtweener .l-wrapper .component:after,
  .flexboxtweener .l-wrapper :only-of-type:last-of-type.component:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener .l-wrapper,
.flexboxtweener .l-content,
.flexboxtweener .l-content-main {
  display: block !important;
  width: 100%;
  flex: none; }
  .flexboxtweener .l-wrapper:after,
  .flexboxtweener .l-content:after,
  .flexboxtweener .l-content-main:after {
    content: " ";
    display: block;
    clear: both; }

.flexboxtweener [class*="l-box"] {
  display: block;
  flex: none; }

.flexboxtweener .l-col-2 {
  width: calc(100%/2);
  max-width: calc(100%/2); }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-2 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-3 {
  width: calc(100%/3);
  max-width: calc(100%/3); }
  @media (max-width: 900px) {
    .flexboxtweener .l-col-3 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-3 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-4 {
  width: calc(100%/4);
  max-width: calc(100%/4); }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-4 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-4 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-5 {
  width: calc(100%/5);
  max-width: calc(100%/5); }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-5 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-5 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-6 {
  width: calc(100%/6);
  max-width: calc(100%/6); }
  @media (max-width: 1000px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/4);
      max-width: calc(100%/4); } }
  @media (max-width: 800px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/3);
      max-width: calc(100%/3); } }
  @media (max-width: 700px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-6 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

.flexboxtweener .l-col-7 {
  width: calc(100%/7);
  max-width: calc(100%/7); }
  @media (max-width: 1100px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/5);
      max-width: calc(100%/5); } }
  @media (max-width: 900px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/4);
      max-width: calc(100%/4); } }
  @media (max-width: 700px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/3);
      max-width: calc(100%/3); } }
  @media (max-width: 600px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/2);
      max-width: calc(100%/2); } }
  @media (max-width: 500px) {
    .flexboxtweener .l-col-7 {
      width: calc(100%/1);
      max-width: calc(100%/1); } }

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html[lang="en-US"],
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100%;
  width: 100%; }

html {
  height: 100%;
  font-size: 100%;
  touch-action: manipulation; }
  html.slideout-open {
    overflow: hidden; }

body {
  width: 100%;
  letter-spacing: 0.01em;
  line-height: 1.45;
  font-family: "Libre Baskerville", serif;
  color: #686868;
  -webkit-backface-visibility: hidden;
  padding-top: 90px; }
  body.admin-bar {
    padding-top: 58px; }
  body * + * {
    margin-top: 1.5rem; }
  @media (min-width: 1600px) {
    body {
      line-height: 1.5; } }

code, kbd, pre, samp {
  border-radius: 0.68301em; }

ul, ol, dl {
  margin-top: 0;
  margin-bottom: 1.1em;
  list-style: none;
  padding-left: 2.662em; }
  ul li, ol li, dl li {
    margin-top: 0.68301em; }

.main ul {
  list-style-type: none;
  margin-top: -1em;
  margin-bottom: 3em; }
  .main ul > li {
    text-indent: -5px; }
    .main ul > li:before {
      content: "–";
      position: relative;
      left: -5px; }
    .main ul > li.tribe-events-nav-next:before, .main ul > li.tribe-bar-views-option:before {
      display: none; }

svg,
img {
  max-width: 100%; }
  @media (max-width: 600px) {
    svg,
    img {
      max-width: 100%;
      width: 100%;
      display: block; } }

hr {
  border: 1px solid;
  margin: 0; }

p {
  font-family: "Libre Baskerville", serif; }

iframe {
  border: none; }

.no-margin-bottom {
  margin-bottom: 0; }

@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?568okp");
  src: url("../fonts/icomoon.eot?568okp#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?568okp") format("truetype"), url("../fonts/icomoon.woff?568okp") format("woff"), url("../fonts/icomoon.svg?568okp#icomoon") format("svg");
  font-weight: normal;
  font-style: normal; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-arrow-double-left:before {
  content: "\e900"; }

.icon-arrow-double-right:before {
  content: "\e901"; }

.icon-arrow-single-left:before {
  content: "\e902"; }

.icon-arrow-single-right:before {
  content: "\e903"; }

.icon-doc:before {
  content: "\e904"; }

.icon-download:before {
  content: "\e905"; }

.icon-dwg:before {
  content: "\e906"; }

.icon-link:before {
  content: "\e907"; }

.icon-protractor:before {
  content: "\e908"; }

.icon-search:before {
  content: "\e909"; }

.icon-sketchboard:before,
.icon-sketchpad:before {
  content: "\e90a"; }

h1, h2, h3, h4, h5, h6 {
  font-family: "Lato", sans-serif;
  line-height: 1.1;
  color: #686868;
  text-transform: uppercase;
  margin: 0 0 1.21em 0;
  font-weight: bold;
  letter-spacing: 0.08em;
  padding-bottom: 0; }
  h1 a, h2 a, h3 a, h4 a, h5 a, h6 a {
    color: #686868; }
  h1 + p, h2 + p, h3 + p, h4 + p, h5 + p, h6 + p {
    margin-top: 0.5em; }

h1 {
  font-size: 1.61051em; }
  @media (max-width: 600px) {
    h1 {
      font-size: 1.21em; } }

h2 {
  font-size: 1.21em; }

h3, h4 {
  font-size: 1.1em; }
  @media (max-width: 500px) {
    h3, h4 {
      font-size: 1.61051em; } }

h5, h6 {
  font-size: 1em; }

a {
  color: #686868;
  text-decoration: underline;
  font-weight: 100; }
  a:hover {
    cursor: pointer;
    color: #eae5df; }

p {
  line-height: 1.8;
  margin-top: 1em;
  margin-bottom: 2em; }

blockquote {
  padding: 0 0 0 1.1em;
  font-style: italic;
  border-left: 3px solid #686868;
  margin: 2.5em 0 2.5em 1.5em;
  position: relative;
  width: 120%;
  left: -14%;
  font-size: 1.1em; }
  @media (max-width: 500px) {
    blockquote {
      margin-left: 0; } }

textarea,
input,
select,
input,
#give-recurring-form .form-row input[type=email].required,
#give-recurring-form .form-row input[type=password].required,
#give-recurring-form .form-row input[type=tel].required,
#give-recurring-form .form-row input[type=text].required,
#give-recurring-form .form-row input[type=url].required,
#give-recurring-form .form-row select.required,
#give-recurring-form .form-row textarea.required,
form.give-form .form-row input[type=email].required,
form.give-form .form-row input[type=password].required,
form.give-form .form-row input[type=tel].required,
form.give-form .form-row input[type=text].required,
form.give-form .form-row input[type=url].required,
form.give-form .form-row select.required,
form.give-form .form-row textarea.required,
form[id*=give-form] .form-row input[type=email].required,
form[id*=give-form] .form-row input[type=password].required,
form[id*=give-form] .form-row input[type=tel].required,
form[id*=give-form] .form-row input[type=text].required,
form[id*=give-form] .form-row input[type=url].required,
form[id*=give-form] .form-row select.required,
form[id*=give-form] .form-row textarea.required,
form[id*=give-form] input[type="text"].give-input,
form[id*=give-form] input[type="text"].textfield {
  font-size: 1em;
  width: 100%;
  display: block;
  padding: 0.75131em;
  font-weight: 100;
  font-family: "Lato", sans-serif;
  line-height: 1;
  border: 1px solid #ccc;
  height: auto;
  border-radius: 0;
  color: #686868;
  background-color: #fdfdfd; }

select,
form.give-form .form-row select.required,
form[id*=give-form] .form-row select.required,
.select2-container,
.select2-selection--single,
.select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single {
  height: 42px; }

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.5; }

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px; }

form[id*=give-form] #give-ffm-section {
  margin-top: 2em; }

[id*=give-form] fieldset {
  margin-bottom: 1.5em; }
  [id*=give-form] fieldset legend {
    font-size: 1em;
    font-family: "Lato", sans-serif; }

label,
.give-label,
.ffm-radio-field label,
.give-donation-amount {
  text-transform: initial;
  font-family: "Lato", sans-serif; }

.ffm-fields.ffm-radio-field {
  display: flex;
  margin-top: 0; }
  .ffm-fields.ffm-radio-field label {
    margin: 0; }
    .ffm-fields.ffm-radio-field label:first-child {
      margin-right: 1em; }

.give-total-wrap,
.select2-selection__arrow {
  margin-top: 0; }

.give-custom-amount-text {
  margin-bottom: 2em;
  font-weight: bold;
  line-height: 1;
  font-size: 80%; }

.give-donation-total-label,
.give-final-total-amount {
  font-size: 22px; }

.post-password-form input[name="Submit"] {
  width: auto; }

.row,
.widget .row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  list-style: none;
  flex: 0 1 auto;
  margin-top: 0;
  padding: 0; }

.col {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  min-width: 0;
  min-height: 1px; }

.contain {
  max-width: 1140px;
  padding: 0 0.68301em;
  margin: 0 auto;
  padding: 0 1.1em;
  max-width: 1140px; }

.contain-narrow {
  max-width: 800px;
  margin: 0 auto; }
  @media (max-width: 1000px) {
    .contain-narrow {
      max-width: 700px; } }
  @media (max-width: 600px) {
    .contain-narrow {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0; } }

.contain-extra-narrow {
  max-width: 600px;
  margin: 0 auto; }
  @media (max-width: 1000px) {
    .contain-extra-narrow {
      max-width: 700px; } }
  @media (max-width: 600px) {
    .contain-extra-narrow {
      max-width: 100%;
      padding-left: 0;
      padding-right: 0; } }

/*

Columns (as a proportion)
E.g., 2 === one half, 3 === one third, 4 === one fourth, etc

*/
.col-3 {
  max-width: 33.3%; }

.col-4 {
  max-width: 25%; }

.col-auto {
  flex: 1; }

.col-reverse {
  flex-direction: column-reverse; }

.col-center {
  align-items: center; }

.col-left {
  align-self: flex-start; }

.col-end {
  align-items: flex-end; }

.order-1 {
  order: 1; }

.order-2 {
  order: 2; }

.order-3 {
  order: 3; }

.order-4 {
  order: 4; }

.order-5 {
  order: 5; }

.order-6 {
  order: 6; }

.order-7 {
  order: 7; }

.row-left {
  justify-content: flex-start; }

.row-right {
  justify-content: flex-end; }

.row-center {
  justify-content: center;
  margin: 0 auto; }

.row-space-between {
  justify-content: space-between; }

.row-space-around {
  justify-content: space-around; }

.row-reverse {
  flex-direction: row-reverse; }

.fit {
  flex-grow: 1; }

.fill {
  flex: 1;
  min-width: 0; }

.contained {
  flex: none; }

[class*=col-] {
  margin-top: 0; }

[class*=col-],
[class*=col_],
[class~=col] {
  padding: 0 .5rem .5rem; }
  [class*=col-] > .col-content > .heading h1,
  [class*=col-] > .col-content > .heading h2,
  [class*=col-] > .col-content > .heading h3,
  [class*=col-] > .col-content > .heading h4,
  [class*=col-] > .col-content > .heading h5,
  [class*=col-] > .col-content > .heading h6,
  [class*=col_] > .col-content > .heading h1,
  [class*=col_] > .col-content > .heading h2,
  [class*=col_] > .col-content > .heading h3,
  [class*=col_] > .col-content > .heading h4,
  [class*=col_] > .col-content > .heading h5,
  [class*=col_] > .col-content > .heading h6,
  [class~=col] > .col-content > .heading h1,
  [class~=col] > .col-content > .heading h2,
  [class~=col] > .col-content > .heading h3,
  [class~=col] > .col-content > .heading h4,
  [class~=col] > .col-content > .heading h5,
  [class~=col] > .col-content > .heading h6 {
    margin-top: 0; }

.l-center {
  text-align: center; }

@media (max-width: 800px) {
  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6,
  .col-7,
  .col-8,
  .col-9,
  .col-10,
  .col-11,
  .col-12 {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%; } }

.grid.masonry-container {
  justify-content: space-between; }
  .grid.masonry-container .masonry-block {
    margin-bottom: 0.62092em; }

.col-1 {
  flex: 100%;
  max-width: 100%; }

.col-2 {
  flex: calc(100% / 2);
  max-width: calc(100% / 2 - 0.46651em); }
  @media (max-width: 800px) {
    .col-2 {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%; } }

.col-3 {
  flex: calc(100% / 3);
  max-width: calc(100% / 3 - 0.46651em); }
  @media (max-width: 800px) {
    .col-3 {
      width: 100%;
      flex-basis: 100%;
      max-width: 100%; } }

.col-4 {
  flex: calc(100% / 4 - 0.46651em);
  max-width: calc(100% / 4 - 0.46651em); }
  @media (max-width: 1000px) {
    .col-4 {
      flex: calc(100% / 2 - 0.46651em);
      max-width: calc(100% / 2 - 0.46651em); } }

.col-5 {
  flex: calc(100% / 5 - 0.46651em);
  max-width: calc(100% / 5 - 0.46651em); }

.col-6 {
  flex: calc(100% / 6 - 0.46651em);
  max-width: calc(100% / 6 - 0.46651em); }
  @media (max-width: 1000px) {
    .col-6 {
      flex: calc(100% / 2 - 0.46651em);
      max-width: calc(100% / 2 - 0.46651em); } }

.col-7 {
  flex: calc(100% / 7 - 0.46651em);
  max-width: calc(100% / 7 - 0.46651em); }

.col-8 {
  flex: calc(100% / 8 - 0.46651em);
  max-width: calc(100% / 8 - 0.46651em); }
  @media (max-width: 1000px) {
    .col-8 {
      flex: calc(100% / 4 - 0.46651em);
      max-width: calc(100% / 4 - 0.46651em); } }

.col-9 {
  flex: calc(100% / 9 - 0.46651em);
  max-width: calc(100% / 9 - 0.46651em); }

.col-10 {
  flex: calc(100% / 10 - 0.46651em);
  max-width: calc(100% / 10 - 0.46651em); }
  @media (max-width: 1000px) {
    .col-10 {
      flex: calc(100% / 5 - 0.46651em);
      max-width: calc(100% / 5 - 0.46651em); } }

.col-11 {
  flex: calc(100% / 11 - 0.46651em);
  max-width: calc(100% / 11 - 0.46651em); }

.col-12 {
  flex: calc(100% / 12);
  max-width: calc(100% / 12); }
  @media (max-width: 1000px) {
    .col-12 {
      flex: calc(100% / 4 - 0.46651em);
      max-width: calc(100% / 4 - 0.46651em); } }

.header {
  margin-top: 0;
  background-color: #eae5df;
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  height: 120px;
  transition: all 0.4s;
  z-index: 999;
  padding: 3em 0 1em 0; }
  @media (max-width: 1000px) {
    .header {
      padding-top: 1em;
      height: 90px; } }

#menu-top-nav {
  align-items: center; }

.header-wrapper {
  margin: 0;
  display: block; }

.is-header-shrink .top-nav {
  transition: all 0.4s;
  opacity: 0;
  transform: translateY(-45px); }

.is-header-shrink .nav-primary {
  transition: all 0.4s;
  transform: translateY(-5px); }

.top-nav {
  position: fixed;
  top: 0;
  right: calc((100vw - 1140px) / 1.8);
  width: 100%;
  z-index: 9999;
  transition: all 0.4s;
  opacity: 1;
  transform: translateY(0px); }
  @media (max-width: 1200px) {
    .top-nav {
      right: 23px; } }
  @media (max-width: 1000px) {
    .top-nav {
      display: none; } }
  .top-nav .nav > .menu-item.link {
    margin-top: 0;
    position: relative;
    top: -3px; }
    .top-nav .nav > .menu-item.link a {
      color: #685d56;
      text-decoration: underline;
      padding-left: 0; }
  .top-nav .nav > .menu-item a {
    margin-top: 0;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0;
    z-index: 999999;
    font-weight: 400;
    font-size: 0.9em;
    color: white;
    padding: .72645em 1.6em;
    display: block; }
  .top-nav .btn {
    margin-top: 0;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0;
    z-index: 999999;
    font-weight: 400;
    padding: 0;
    margin-left: 1em;
    position: relative;
    top: -4px; }

.header-shrink {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 0;
  transition: all 0.4s ease;
  height: 70px; }
  .header-shrink .logo-primary {
    width: 140px;
    padding: 10px; }

.header-container {
  align-items: center;
  position: relative; }

.page-header-image {
  min-height: 335px;
  margin: 0;
  width: 100%;
  line-height: 1;
  background-size: cover;
  background-position: 50% 50%;
  position: relative; }
  .page-header-image .page-heading {
    position: absolute;
    top: 0;
    margin-top: 0;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: "Lato", sans-serif;
    font-size: 2.9282em;
    padding: 0;
    margin: 0; }
  @media (max-width: 500px) {
    .page-header-image {
      min-height: 180px; } }

.logo-wrapper {
  padding: 0; }
  @media (max-width: 1000px) {
    .logo-wrapper {
      flex: 0 0 auto;
      max-width: auto;
      width: auto; } }

.logo-primary {
  height: auto;
  width: 180px;
  transition: all ease 0.5s; }
  @media (max-width: 1000px) {
    .logo-primary {
      max-width: 150px; } }

.page-heading {
  text-align: center;
  text-transform: uppercase;
  margin: 2.2em 0 1.21em 0;
  font-weight: bold;
  letter-spacing: 2px;
  padding-bottom: 1.21em; }
  @media (max-width: 800px) {
    .page-heading {
      margin-top: 1.1em; } }

.footer {
  background: #eae5df;
  margin: 0;
  font-size: 0.90909em;
  padding-top: 3.63em; }
  @media (max-width: 1100px) {
    .footer {
      padding-top: 2.42em; } }

.footer-section {
  background-color: #eae5df;
  margin: 0;
  padding: 0.82645em 0; }
  .footer-section .contain {
    width: 100%; }

.footer-middle {
  padding-top: 1em;
  padding-bottom: 2.42em; }
  @media (max-width: 900px) {
    .footer-middle > .contain {
      display: block; } }
  @media (max-width: 900px) {
    .footer-middle {
      padding-bottom: 0; } }

.footer-bottom {
  overflow: hidden;
  border-top: 1px solid #4a4a4a;
  color: #a1a1a1; }

.footer-social {
  list-style: none;
  margin: 0;
  padding: 0;
  min-width: 290px;
  justify-content: space-between; }
  @media (max-width: 900px) {
    .footer-social {
      min-width: 100%;
      width: 100%;
      justify-content: center; } }

.footer-social-link {
  display: block; }
  .footer-social-link:hover {
    opacity: 0.6; }

.footer-tagline {
  color: white;
  font-weight: 100;
  font-size: 1em;
  padding-left: 16.8%; }
  @media (max-width: 900px) {
    .footer-tagline {
      display: none; } }

.footer-social-image {
  width: 25px;
  height: 50px; }
  @media (max-width: 500px) {
    .footer-social-image {
      height: 30px;
      width: 22px; } }

.footer-social-item {
  margin-top: 0;
  padding-bottom: 0; }
  @media (max-width: 900px) {
    .footer-social-item {
      padding-right: 1.21em;
      padding-left: 1.21em; } }

.footer-copyright {
  text-align: center;
  font-weight: 100;
  font-size: 0.82645em; }

#panel,
#wpadminbar {
  margin-top: 0; }

#comments {
  display: none; }

.main {
  width: 100%;
  margin: 0;
  height: 100%;
  justify-content: center;
  padding-bottom: 0; }
  @media (max-width: 600px) {
    .main h2:first-of-type,
    .main h3:first-of-type {
      margin-top: 0; } }

@media (max-width: 800px) {
  .single-stories .main {
    margin-top: 0; } }

.wrap.container {
  margin: 0 auto;
  overflow-y: visible !important; }
  .wrap.container[data-heading-in-header='1'] {
    padding-top: 2.42em; }
  @media (max-width: 800px) {
    .wrap.container {
      margin-top: 0.68301em; } }

.archive .wrap.container,
.blog .wrap.container {
  margin-top: 0; }

.blog .page-heading {
  font-family: Lato, sans-serif;
  font-size: 42px;
  padding: 0;
  margin: 0; }

.blog .main,
.single-post .main {
  margin-top: 6em;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto;
  padding: 0; }
  .blog .main .contain-narrow,
  .single-post .main .contain-narrow {
    max-width: 100%; }
  .blog .main .entry-title,
  .single-post .main .entry-title {
    font-weight: bold; }

.blog .main .entry-title a {
  font-size: 28px; }

.blog .main .entry-summary > p a {
  display: block !important;
  margin-top: 20px !important;
  width: 160px;
  text-align: center;
  text-transform: uppercase !important; }

.blog .main .entry-summary,
.blog .main article .contain-narrow {
  max-width: 100%;
  font-size: 16px; }

.blog .main .entry-title {
  margin-bottom: 10px; }

.blog .main .byline,
.blog .main .updated {
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1; }

.blog .main .post {
  margin-bottom: 4em;
  max-width: 100%;
  border-bottom: 1px solid #c5c5c5;
  padding-bottom: 1em; }

.blog .main header .entry-categories {
  display: none;
  margin-top: 0; }

@media (max-width: 800px) {
  .has-subnav .main,
  .has-subnav .component {
    padding: 0 1.1em;
    margin: 0 auto; } }

@media (max-width: 600px) {
  .has-subnav .main,
  .has-subnav .component {
    padding: 0; } }

.slideout-open {
  height: 100%;
  overflow: hidden; }
  .slideout-open body {
    -webkit-overflow-scrolling: auto !important;
    height: 100%;
    overflow: hidden; }
    .slideout-open body > * {
      overflow: auto;
      height: 100%; }
  .slideout-open #panel {
    height: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    bottom: 0; }
  .slideout-open .component-subnav .toggle-mobile-menu {
    display: none; }
  .slideout-open .has-subnav .component,
  .slideout-open .has-subnav .main {
    margin-left: 0; }

.search.search-results .component,
.search.search-results .main {
  margin: 0; }

.wp-caption {
  width: 100% !important;
  margin: 0 !important; }

.wp-caption-text {
  margin-top: 10px;
  font-family: "Lato", sans-serif;
  font-weight: 100;
  max-width: 75%; }

.nav {
  margin: 0; }
  .nav > .menu-item a {
    font-family: "Lato", sans-serif;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding: 0.82645em 1.65289em;
    z-index: 999999;
    font-weight: 400; }
  .nav > .menu-item > a {
    position: relative;
    top: 10px;
    font-weight: bold; }
  .nav > .menu-item.btn-menu > a {
    position: relative;
    top: 4px;
    font-size: 14px; }
    .nav > .menu-item.btn-menu > a:hover {
      color: white; }
  .nav > .menu-item .menu-item a {
    text-transform: none;
    letter-spacing: 0; }
  .nav > .menu-item.menu-call {
    margin: 0 1em; }

#menu-top-nav {
  margin-right: -50px; }

.is-active-mobile-menu #menu-mobile-navigation {
  display: block;
  position: absolute;
  background-color: #eae5df;
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 70px;
  padding: 1.21em;
  z-index: -1; }

.header-shrink .is-active-mobile-menu #menu-mobile-navigation {
  top: 60px; }

#menu-mobile-navigation .menu-item a {
  display: block;
  width: 100%; }

.nav,
.sub-menu {
  list-style: none; }

.sub-menu {
  padding-left: 0.82645em;
  background-color: #eae5df; }

.menu-item-has-children .sub-menu {
  display: none;
  position: absolute;
  margin-top: 0;
  padding: 2.7em 0 1em 0;
  min-width: 250px;
  opacity: 0.8;
  z-index: 2; }
  .menu-item-has-children .sub-menu a {
    font-size: 0.90909em;
    padding: 0.68301em 2em;
    display: block; }

.menu-item-has-children.btn-menu .sub-menu {
  padding-top: 25px; }
  .menu-item-has-children.btn-menu .sub-menu a {
    background-color: transparent;
    color: #685d56; }

.menu-item-has-children .svg-inline--fa {
  margin-right: 1em; }
  @media (min-width: 1000px) {
    .menu-item-has-children .svg-inline--fa {
      display: none; } }

.menu-item-has-children.is-active .sub-menu {
  display: block; }

.nav-primary {
  padding: 0; }
  @media (max-width: 1000px) {
    .nav-primary {
      display: none; } }

.toggle-mobile-menu-ham {
  padding: 10px;
  opacity: 1;
  overflow: hidden;
  transition: opacity ease 1.3s; }
  .toggle-mobile-menu-ham:hover {
    cursor: pointer; }

.toggle-mobile-menu {
  position: absolute;
  margin: 0;
  right: 10px;
  top: calc(50% - 27px);
  max-width: 70px; }
  .toggle-mobile-menu.toggle-mobile-menu-active {
    display: none;
    opacity: 0; }
    .toggle-mobile-menu.toggle-mobile-menu-active + .toggle-mobile-menu-close {
      display: block;
      opacity: 1; }

.toggle-mobile-menu-close {
  display: none;
  padding: 10px;
  opacity: 0;
  transition: opacity ease 1.3s; }
  .toggle-mobile-menu-close:hover {
    cursor: pointer; }

.nav-mobile {
  display: none; }
  .nav-mobile .nav > .menu-item > a {
    padding-left: 0;
    padding-right: 0; }
  .nav-mobile .menu-item-has-children .sub-menu.is-active {
    display: block;
    position: static;
    padding-top: 0; }
  @media (max-width: 1000px) {
    .nav-mobile {
      display: block; }
      .nav-mobile .nav {
        display: none; } }

.menu-item a {
  color: #887f6f;
  font-size: 0.82645em;
  padding: 6px 25px;
  text-decoration: none; }
  .menu-item a:hover {
    color: #685d56; }

.header .menu-item {
  margin: 0; }

.post {
  margin-bottom: 3.63em;
  padding-bottom: 2.42em;
  border-bottom: 1px solid;
  max-width: 920px;
  margin-left: auto;
  margin-right: auto; }

.heateor_sss_sharing_title {
  font-family: "Lato", sans-serif; }

.byline,
.updated {
  margin-top: 0;
  font-family: "Lato", sans-serif;
  font-weight: normal;
  font-size: 1.21em; }

.single-post .entry-title {
  margin-bottom: 1.1em;
  font-size: 42px; }

.single-post .type-post .entry-title {
  margin-bottom: 10px; }

.single-post .byline.author {
  margin-bottom: 0; }

.single-post .entry-content {
  max-width: 100%; }
  .single-post .entry-content p,
  .single-post .entry-content li {
    font-size: 16px; }
  .single-post .entry-content p {
    margin: 2em 0; }
  .single-post .entry-content li {
    margin-top: 0; }
  .single-post .entry-content h1,
  .single-post .entry-content h2,
  .single-post .entry-content h3,
  .single-post .entry-content h4,
  .single-post .entry-content h5,
  .single-post .entry-content h6 {
    font-size: 1.6em;
    margin-bottom: 1.2em;
    margin-top: 2em;
    text-transform: initial;
    color: #686868;
    font-weight: 400;
    line-height: 1.4; }

.single-post blockquote {
  text-transform: uppercase;
  color: #eae5df;
  border: none;
  font-style: normal;
  margin: 2.5em 0 2.5em 1.5em;
  position: relative;
  width: 100%;
  left: 0;
  font-size: 1.3em;
  font-family: "Lato", sans-serif;
  padding-left: 3em; }

.byline {
  margin-right: 2em; }
  .byline span {
    text-decoration: none;
    font-weight: normal;
    position: relative;
    text-transform: capitalize; }
    .byline span:after {
      content: '';
      display: block;
      background: black;
      width: 18px;
      height: 1px;
      position: absolute;
      right: -25px;
      top: 11px; }

.posts-navigation {
  display: flex;
  justify-content: center;
  margin-bottom: 4em;
  text-align: center; }
  .posts-navigation .screen-reader-text {
    margin-right: 1em;
    display: none; }
  .posts-navigation .screen-reader-text,
  .posts-navigation .nav-links {
    margin-top: 0; }
  .posts-navigation .nav-links {
    display: flex; }
  .posts-navigation .nav-previous,
  .posts-navigation .nav-next {
    margin-top: 0;
    margin-left: 10px;
    margin-right: 10px; }

.feat-img-wrapper {
  max-width: 100%;
  margin: 1em 0;
  display: block; }
  .feat-img-wrapper img {
    max-width: 100%;
    display: block; }

.entry-title {
  text-decoration: none;
  font-size: 2.057em;
  font-weight: 400;
  text-transform: initial; }
  .entry-title a {
    text-decoration: none;
    font-weight: bold; }

.entry-sub-title {
  text-transform: capitalize;
  color: #686868;
  margin-top: 0;
  margin-bottom: 2.2em;
  font-weight: 100; }

.entry-summary {
  max-width: 800px;
  margin: 0 auto;
  font-family: "Lato", sans-serif;
  font-size: 1.21em; }

.entry-content {
  max-width: 800px;
  margin: 0 auto;
  line-height: 1.8;
  max-width: 100%; }

.faqs.type-faqs header {
  display: none; }

.entry-category {
  margin: 0 7px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-family: "Lato", sans-serif;
  display: inline-block;
  color: #4a4a4a; }

.categories-name {
  font-family: "Lato", sans-serif;
  font-size: 15px;
  font-weight: bold;
  margin-right: 0.7em; }

.entry-categories {
  margin-top: 1em;
  margin-bottom: 2em; }
  .entry-categories a {
    display: inline-block;
    text-decoration: underline;
    text-transform: capitalize;
    padding: 0 10px;
    margin: 0;
    font-size: 15px; }
  .entry-categories a:first-child {
    margin-left: 0; }

.component {
  margin: 1.1em 0;
  position: relative; }

.components > section:last-child {
  margin-bottom: 5.5em; }

.component-last {
  margin-bottom: 5.5em; }

.widget {
  width: 25;
  max-width: 25%;
  flex: 0 0 25;
  margin-top: 0; }
  .widget input {
    border: none !important; }
  @media (max-width: 1100px) {
    .widget {
      margin-bottom: 1.331em;
      width: 50%;
      max-width: 50%;
      flex: 0 0 50%;
      padding: 1.21em; }
      .widget .nav-primary {
        display: block; } }
  @media (max-width: 600px) {
    .widget {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%; } }

.widget-contact-email {
  display: block;
  margin-top: 1em;
  text-decoration: none; }
  .widget-contact-email:hover {
    color: #685d56; }

.widget-contact-email,
.widget-contact-phone,
.widget-description {
  color: #a1a1a1;
  font-size: 13px;
  margin: 1.65289em 0; }

.widget-support .so-widget-image {
  max-width: 100px; }

.widget-support .btn-donate {
  margin: 2em 0;
  font-size: 1.2em;
  background-color: #685d56;
  color: white; }
  .widget-support .btn-donate:hover {
    opacity: 0.6; }

.widget-contact-address {
  margin-bottom: 0;
  margin-top: 0;
  color: #a1a1a1;
  font-size: 13px; }
  .widget-contact-address .svg-inline--fa {
    margin-right: 15px;
    position: relative;
    top: 5px;
    left: 2px; }

.widget-contact-address-line-2 {
  margin-left: 29px; }

.widget-heading,
.null-instagram-feed h3 {
  font-size: 1.1em;
  color: #686868;
  font-weight: bold;
  padding-bottom: 0.68301em;
  margin-bottom: 0.82645em;
  margin-top: 0;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase; }
  @media (max-width: 900px) {
    .widget-heading,
    .null-instagram-feed h3 {
      font-size: 1.4641em; } }

.widget-social-icon a {
  font-size: 24px;
  padding-right: 1em;
  color: #a1a1a1;
  display: inline-block; }

.widget .widget-social-icons {
  margin: 2.42em 0; }

.widget-contact .menu {
  padding: 0;
  list-style: none; }

.widget-contact .menu-item {
  margin: 0; }
  .widget-contact .menu-item a {
    padding-left: 0;
    margin: 0;
    padding-top: 0; }

.widget-contact .svg-inline--fa {
  font-size: 20px;
  opacity: 0.7;
  margin-right: 10px;
  position: relative;
  top: 5px; }

.widget-services {
  width: 23%; }
  .widget-services p {
    line-height: 1; }
    .widget-services p br {
      display: none; }
  .widget-services a {
    color: #a1a1a1;
    padding: 5px 0;
    font-size: 13px;
    display: block;
    margin-top: 0;
    text-decoration: none; }
    .widget-services a:hover {
      color: #685d56; }

.widget-description {
  margin-top: 0; }

.instagram-pics {
  padding: 0; }
  .instagram-pics + .clear {
    display: none; }
  .instagram-pics a:hover {
    opacity: 0.6; }

.widget .instagram-pics li {
  width: 31.3%;
  margin-right: 2%;
  display: inline-block;
  padding: 0;
  border: none; }

.widget-social-link:hover {
  color: #685d56; }

.widget-contact-address-line-1 {
  margin: 0; }

.carousel-wrapper,
.carousel-slide {
  margin: 0;
  min-height: 500px;
  background-size: cover;
  background-position: 50% 50%;
  background-repeat: no-repeat; }

.carousel {
  margin-top: 0;
  width: 100%;
  padding: 0;
  opacity: 0;
  min-height: 500px;
  transition: all ease 0.5s; }
  .carousel.slick-slider {
    margin-top: 1.8em; }
    @media (max-width: 1000px) {
      .carousel.slick-slider {
        margin-top: 0; } }
  .carousel.slick-initialized {
    opacity: 1; }
  .carousel .slick-dots {
    list-style: none;
    padding: 0;
    margin: 0; }

.slick-prev,
.slick-next {
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: 50%;
  display: block;
  width: 40px;
  height: 40px;
  padding: 0;
  -webkit-transform: translate(0, -50%);
  -ms-transform: translate(0, -50%);
  transform: translate(0, -50%);
  cursor: pointer;
  color: transparent;
  border: none;
  outline: none;
  background: #4a4a4a;
  border-radius: 50%;
  margin: 0;
  z-index: 1; }
  .slick-prev:hover,
  .slick-next:hover {
    opacity: 0.75; }

.slick-next {
  right: 25px; }

.slick-prev {
  left: 25px; }

.slick-prev:hover:before,
.slick-prev:focus:before,
.slick-next:hover:before,
.slick-next:focus:before {
  opacity: 1; }

.slick-prev:before {
  content: url("../imgs/icon-arrow-prev.svg"); }

.slick-next:before {
  content: url("../imgs/icon-arrow-next.svg"); }

.slick-prev:before,
.slick-next:before {
  background-size: contain;
  line-height: 1;
  opacity: 1;
  color: white;
  font-weight: 300;
  display: block;
  font-style: normal;
  font-variant: normal;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  width: 26px;
  height: 26px;
  margin: 0 auto; }

.carousel-slide {
  position: relative; }

.carousel-slide-content {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  max-width: 1100px;
  min-width: 900px;
  margin: 0 auto;
  text-align: center;
  padding: 4.4em 4.4em;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%); }
  @media (max-width: 1000px) {
    .carousel-slide-content {
      min-width: 90%; } }
  @media (max-width: 500px) {
    .carousel-slide-content {
      padding: 3em 2em; } }

.carousel-slide-content,
.carousel-slide-content h1,
.carousel-slide-content h2,
.carousel-slide-content h3,
.carousel-slide-content h4,
.carousel-slide-content h5,
.carousel-slide-content h6 {
  color: white;
  text-transform: none;
  font-weight: normal;
  letter-spacing: 0;
  margin-bottom: 1em;
  line-height: 1; }
  .carousel-slide-content:last-child,
  .carousel-slide-content h1:last-child,
  .carousel-slide-content h2:last-child,
  .carousel-slide-content h3:last-child,
  .carousel-slide-content h4:last-child,
  .carousel-slide-content h5:last-child,
  .carousel-slide-content h6:last-child {
    margin-bottom: 0; }

.carousel-slide-content p:last-child {
  margin-bottom: 0; }

.carousel-slide-content .btn {
  font-size: 1.21em; }

.carousel-slide-content h1 {
  font-size: 3.63em;
  margin-bottom: 0.7em; }
  @media (max-width: 900px) {
    .carousel-slide-content h1 {
      font-size: 2.42em; } }
  @media (max-width: 500px) {
    .carousel-slide-content h1 {
      font-size: 1.573em; } }

.carousel-slide-content h2 {
  font-size: 1.81818em; }
  @media (max-width: 900px) {
    .carousel-slide-content h2 {
      font-size: 1.452em; } }
  @media (max-width: 500px) {
    .carousel-slide-content h2 {
      font-size: 1.1em; } }

.carousel-slide-content h3 {
  font-size: 1.4641em;
  white-space: normal;
  font-weight: 700; }
  @media (max-width: 500px) {
    .carousel-slide-content h3 {
      font-size: 1em;
      font-weight: normal; } }

.officer-name,
.officer-title,
.officer-seperator {
  margin: 0;
  font-size: 1.21em; }

.officer-name {
  font-weight: bold; }

.officer-seperator {
  padding: 0 0.62092em; }

.officer-description {
  font-family: "Libre Baskerville", serif; }

.officer {
  padding: 2.42em 0;
  border-top: 1px solid; }
  @media (max-width: 600px) {
    .officer {
      margin: 0; } }
  @media (max-width: 600px) {
    .officer .officer-image {
      max-width: 200px;
      margin: 0 auto; } }
  .officer:nth-child(even) {
    flex-direction: row-reverse; }
    @media (max-width: 600px) {
      .officer:nth-child(even) {
        flex-direction: row; } }
  .officer:nth-child(odd) .officer-meta,
  .officer:nth-child(odd) .officer-description {
    padding-left: 1em; }
    @media (max-width: 600px) {
      .officer:nth-child(odd) .officer-meta,
      .officer:nth-child(odd) .officer-description {
        padding-left: 0; } }
  .officer:last-child {
    padding-bottom: 0; }

.officer-info {
  padding-left: 0;
  flex: 1; }
  @media (max-width: 600px) {
    .officer-info {
      padding: 1em; } }

.officer-meta {
  display: flex;
  flex-flow: row wrap; }

.component-generic {
  margin: 3.3em 0; }
  .component-generic .col-content h1:first-of-type, .component-generic .col-content h2:first-of-type, .component-generic .col-content h3:first-of-type, .component-generic .col-content h4:first-of-type, .component-generic .col-content h5:first-of-type, .component-generic .col-content h6:first-of-type {
    margin-top: 0; }
  .component-generic .col-content p:first-of-type {
    margin-top: 0; }
  .component-generic .col-content p:last-of-type {
    margin-bottom: 0; }
  @media (max-width: 500px) {
    .component-generic .grid {
      flex-direction: column; } }

.drawer-answer {
  font-family: "Libre Baskerville", serif;
  margin-left: 0;
  margin-top: 0;
  padding: 0; }

.drawer-question {
  text-transform: uppercase;
  font-size: 1.21em; }

.drawers-group {
  margin-top: 0.56447em; }

.drawers-heading {
  font-size: 1.21em;
  background: #f2f2f2;
  color: #686868;
  padding: 1em 1.21em;
  margin-bottom: 0;
  margin-top: 0;
  border-radius: 5px;
  text-transform: none;
  font-weight: normal;
  transition: all ease 0.5s; }
  .drawers-heading:hover {
    background: #e5e5e5;
    cursor: pointer; }
  .drawers-heading .svg-inline--fa {
    float: right; }

.component-drawers {
  margin-top: 0; }
  .component-drawers .drawers {
    display: none;
    margin-top: 0;
    padding: 1.1em 2.2em;
    margin-bottom: 2.2em; }

.drawers-content {
  display: none;
  font-family: "Libre Baskerville", serif;
  margin-top: 0.82645em;
  margin-bottom: 1.4641em; }
  .drawers-content p:first-of-type {
    margin-top: 0; }

.btn,
a.um-button,
input[type='submit'],
button,
.button,
.nav-previous a,
.nav-next a,
#give-purchase-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
#tribe-events .tribe-events-button,
.tribe-events-button,
.tribe-events-back a,
#tribe-events .tribe-events-button:hover,
.tribe-events-read-more,
#tribe-events #tribe-events-content .tribe-events-button,
.btn-menu a,
.blog .main .entry-summary > p a {
  padding: 0.82645em 2em;
  text-decoration: none;
  text-transform: capitalize;
  font-size: 1em;
  font-family: "Lato", sans-serif;
  display: inline-block;
  border-radius: 0;
  position: relative;
  line-height: 1;
  font-weight: 400;
  box-shadow: none;
  outline: none;
  opacity: 1;
  transition: none;
  background-color: #685d56;
  color: white;
  transition: all ease 0.5s; }
  .btn:hover,
  a.um-button:hover,
  input[type='submit']:hover,
  button:hover,
  .button:hover,
  .nav-previous a:hover,
  .nav-next a:hover,
  #give-purchase-button:hover,
  .woocommerce #respond input#submit:hover,
  .woocommerce a.button:hover,
  .woocommerce button.button:hover,
  .woocommerce input.button:hover,
  #tribe-events .tribe-events-button:hover,
  .tribe-events-button:hover,
  .tribe-events-back a:hover,
  #tribe-events .tribe-events-button:hover:hover,
  .tribe-events-read-more:hover,
  #tribe-events #tribe-events-content .tribe-events-button:hover,
  .btn-menu a:hover,
  .blog .main .entry-summary > p a:hover {
    opacity: 0.6; }
    .btn:hover.is-disabled,
    a.um-button:hover.is-disabled,
    input[type='submit']:hover.is-disabled,
    button:hover.is-disabled,
    .button:hover.is-disabled,
    .nav-previous a:hover.is-disabled,
    .nav-next a:hover.is-disabled,
    #give-purchase-button:hover.is-disabled,
    .woocommerce #respond input#submit:hover.is-disabled,
    .woocommerce a.button:hover.is-disabled,
    .woocommerce button.button:hover.is-disabled,
    .woocommerce input.button:hover.is-disabled,
    #tribe-events .tribe-events-button:hover.is-disabled,
    .tribe-events-button:hover.is-disabled,
    .tribe-events-back a:hover.is-disabled,
    #tribe-events .tribe-events-button:hover:hover.is-disabled,
    .tribe-events-read-more:hover.is-disabled,
    #tribe-events #tribe-events-content .tribe-events-button:hover.is-disabled,
    .btn-menu a:hover.is-disabled,
    .blog .main .entry-summary > p a:hover.is-disabled {
      opacity: 0.6; }
  .btn:focus,
  a.um-button:focus,
  input[type='submit']:focus,
  button:focus,
  .button:focus,
  .nav-previous a:focus,
  .nav-next a:focus,
  #give-purchase-button:focus,
  .woocommerce #respond input#submit:focus,
  .woocommerce a.button:focus,
  .woocommerce button.button:focus,
  .woocommerce input.button:focus,
  #tribe-events .tribe-events-button:focus,
  .tribe-events-button:focus,
  .tribe-events-back a:focus,
  #tribe-events .tribe-events-button:hover:focus,
  .tribe-events-read-more:focus,
  #tribe-events #tribe-events-content .tribe-events-button:focus,
  .btn-menu a:focus,
  .blog .main .entry-summary > p a:focus {
    border: none;
    outline: none;
    background-color: #685d56;
    color: white; }

.link-dark {
  color: #685d56; }

.btn-lg {
  font-size: 1.21em; }

.btn-secondary,
.page.woocommerce #respond input#submit,
.page.woocommerce a.button,
.page.woocommerce button.button,
.page.woocommerce input.button {
  color: #686868; }
  .btn-secondary:hover,
  .page.woocommerce #respond input#submit:hover,
  .page.woocommerce a.button:hover,
  .page.woocommerce button.button:hover,
  .page.woocommerce input.button:hover {
    background-color: #4a4a4a;
    color: white;
    cursor: pointer; }
    .btn-secondary:hover.is-disabled,
    .page.woocommerce #respond input#submit:hover.is-disabled,
    .page.woocommerce a.button:hover.is-disabled,
    .page.woocommerce button.button:hover.is-disabled,
    .page.woocommerce input.button:hover.is-disabled {
      background-color: #4a4a4a; }

.btn-tertiary {
  background-color: #eae5df;
  border: none;
  color: #686868; }
  .btn-tertiary:hover {
    background-color: #ccc6be;
    color: #686868;
    opacity: 1; }
  .btn-tertiary:focus {
    background-color: #eae5df;
    color: #686868;
    opacity: 1; }

#give-purchase-button,
.page.woocommerce #payment #place_order,
.page.woocommerce-page #payment #place_order,
.page.woocommerce #respond input#submit.alt,
.page.woocommerce a.button.alt,
.page.woocommerce button.button.alt,
.page.woocommerce input.button.alt,
#tribe-bar-form .tribe-bar-submit input[type='submit'] {
  background-color: #685d56;
  color: white; }
  #give-purchase-button:hover,
  .page.woocommerce #payment #place_order:hover,
  .page.woocommerce-page #payment #place_order:hover,
  .page.woocommerce #respond input#submit.alt:hover,
  .page.woocommerce a.button.alt:hover,
  .page.woocommerce button.button.alt:hover,
  .page.woocommerce input.button.alt:hover,
  #tribe-bar-form .tribe-bar-submit input[type='submit']:hover {
    opacity: 0.5; }

.tribe-events-list .tribe-events-list-separator-month {
  padding: 12px 16px; }

.tribe-events-list .tribe-events-read-more {
  font-size: 16px; }

.tribe-events-list .tribe-events-list-event-title .tribe-event-url {
  font-size: 18px;
  margin-bottom: 10px;
  display: block; }

.tribe-events-list .tribe-events-ical {
  display: none !important; }

.single-tribe_events .page-heading {
  display: none; }

#tribe-events-bar * {
  margin-top: 0; }

.tribe-events-calendar .tribe-events-tooltip {
  padding: 1em; }

.single-tribe_events .tribe-events-single-event-title {
  margin-top: 2em; }

.tribe-events-tooltip .tribe-events-event-body .tribe-events-event-thumb img {
  max-width: 100%;
  height: auto; }

.post-type-archive-tribe_events .page-heading {
  display: none; }

.single-tribe_events .tribe-events-single-event-title {
  text-align: center; }

.tribe-events-single .tribe-events-nav-pagination {
  display: none; }

.tribe-events-single .tribe-events-schedule {
  text-align: center; }

.tribe-events-event-body,
.datepicker-months {
  margin-top: 0; }

#tribe-events-content .tribe-event-description {
  font-size: 1.21em; }
  #tribe-events-content .tribe-event-description a {
    text-decoration: underline; }

.tribe-events-event-thumb {
  margin-top: 10px; }

#tribe-events-content .tribe-events-calendar div[id*='tribe-events-event-'] h3.tribe-events-month-event-title {
  font-family: "Libre Baskerville", serif; }

.tribe-event-duration {
  font-weight: bold;
  font-size: 1.21em; }

.tribe-events-tooltip .entry-title {
  font-size: 1.61051em; }

.single-tribe_events #tribe-events-header {
  margin-bottom: 5em; }

.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'],
.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'] > a {
  background-color: #685d56; }

.single-tribe_events .tribe-events-event-meta dl {
  padding-left: 0; }

.single-tribe_events #tribe-events-content .tribe-events-event-meta dt {
  font-size: 16px;
  padding-bottom: 5px;
  margin-top: 27px; }

#tribe-events-content .tribe-events-single-section a {
  text-decoration: underline; }

#tribe-events-content {
  margin-bottom: 100px; }

#tribe-events-content-wrapper {
  margin-top: 4em; }

.tribe-events-shortcode.tribe-events-view-wrapper.view-list #tribe-bar-form,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list #tribe-events-header,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list #tribe-events-footer,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-title-bar,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-ical.tribe-events-button,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-event-image,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-event-cost,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-venue-details,
.tribe-events-shortcode.tribe-events-view-wrapper.view-list .tribe-events-list-event-description p {
  display: none; }

.tribe-events-event-meta-custom {
  margin: 1em 0 0 0; }
  .tribe-events-event-meta-custom h3 {
    text-transform: initial;
    margin-bottom: 10px; }
  .tribe-events-event-meta-custom p {
    margin: 0; }
  .tribe-events-event-meta-custom p + h3 {
    margin-top: 1em; }

.tribe-mini-calendar-event .list-info .tribe-events-event-meta-custom p {
  display: block; }

.bereavement-services .tribe-mini-calendar-event .list-info h2 {
  font-size: 18px;
  margin-bottom: 10px;
  display: block;
  color: #686868;
  text-transform: initial;
  line-height: 1.4; }

.bereavement-services .tribe-mini-calendar-event .list-info h2 a {
  text-decoration: none; }

.bereavement-services .type-tribe_events + .tribe-events-widget-link,
.bereavement-services .tribe-mini-calendar-event .list-date {
  display: none; }

.bereavement-services div.type-tribe_events:last-of-type {
  margin-bottom: 3em; }

.bereavement-services .tribe-mini-calendar-event {
  border-bottom: 1px solid #cdcdcd;
  margin-bottom: 5px;
  overflow: hidden;
  padding-bottom: 38px;
  width: 100%; }

.single.single-tribe_events .tribe-events-event-meta-custom {
  display: none; }

.widget-testimonials .slick-prev,
.widget-testimonials .slick-next {
  top: -30px;
  width: 20px;
  height: 20px; }
  .widget-testimonials .slick-prev:before,
  .widget-testimonials .slick-next:before {
    width: 13px;
    height: 13px; }

.widget-testimonials .slick-prev {
  left: 180px; }

.widget-testimonials blockquote {
  border: none;
  margin: 0;
  padding: 0;
  width: 100%;
  left: 0; }

.widget-testimonials .slick-slider,
.widget-testimonials .testimonial {
  margin-top: 0; }

.is-visible {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important; }

.no-pseudo:after,
.no-pseudo:before {
  content: none; }

.is-hidden {
  display: none;
  opacity: 0; }

.is-hidden-by-default {
  visibility: hidden; }

.is-gone {
  visibility: hidden; }

.is-fadeout {
  opacity: 0; }

.is-circle {
  border-radius: 50%;
  display: block;
  -webkit-backface-visibility: hidden; }

.is-foreground {
  z-index: 9999999999999999999999999; }

.is-align-right {
  text-align: right; }

.is-closer {
  transition: transform 0.2s ease; }
  .is-closer:hover {
    transform: scale(1.02);
    z-index: 2; }

.is-loading {
  opacity: 0.6; }

.is-spinner {
  position: relative; }

.is-spinner-sm {
  position: relative; }

.spinner {
  font-size: 1.61051em;
  animation-duration: 0.5;
  z-index: 99999999999999;
  position: absolute;
  top: -6.3%;
  left: 45.8%;
  transform-origin: 50% 48%; }

@keyframes rotate {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }
