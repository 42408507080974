* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html[lang="en-US"],
body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  min-height: 100%;
  width: 100%;
}

html {
  height: 100%;
  font-size: 100%;
  touch-action: manipulation;

  &.slideout-open {
    overflow: hidden;
  }

}

body {
  width: 100%;
  letter-spacing: 0.01em;
  line-height: 1.45;
  font-family: $font-secondary;
  color: color(base);
  -webkit-backface-visibility: hidden;
  padding-top: 90px;

  &.admin-bar {
    padding-top: 58px;
  }

  * + * {
    margin-top: 1.5rem;
  }

  @include respond-to(1600, 'min') {
    line-height: 1.5;
  }

}

::selection {
  background-color: color(msg-notice);
}

code, kbd, pre, samp {
  border-radius: $space-xxs;
}

ul, ol, dl {
  margin-top: 0;
  margin-bottom: $space-l;
  list-style: none;
  padding-left: $space-xxl*2;

  li {
    margin-top: $space-xxs;
  }
}

.main ul {
  list-style-type: none;
  margin-top: -1em;
  margin-bottom: 3em;

  > li {
    text-indent: -5px;

    &:before {
      content: "–";
      position: relative;
      left: -5px;
    }

    &.tribe-events-nav-next,
    &.tribe-bar-views-option {
      &:before {
        display: none;
      }
    }

  }

}


svg,
img {
  max-width: 100%;

  @include respond-to(600, 'max') {
    max-width: 100%;
    width: 100%;
    display: block;
  }

}

hr {
  border: 1px solid color(grey-xxlight);
  margin: 0;
}

p {
  font-family: $font-secondary;
}

iframe {
  border: none;
}

.no-margin-bottom {
  margin-bottom: 0;
}
