.single-tribe_events {
   .page-heading {
      display: none;
   }
}

#tribe-events-bar * {
   margin-top: 0;
}

.tribe-events-calendar .tribe-events-tooltip {
   padding: 1em;
}

.single-tribe_events .tribe-events-single-event-title {
   margin-top: 2em;
}

.tribe-events-tooltip .tribe-events-event-body .tribe-events-event-thumb img {
   max-width: 100%;
   height: auto;
}

.post-type-archive-tribe_events {
   .page-heading {
      display: none;
   }
}

.single-tribe_events .tribe-events-single-event-title {
   text-align: center;
}

.tribe-events-single {
   .tribe-events-nav-pagination {
      display: none;
   }

   .tribe-events-schedule {
      text-align: center;
   }
}

.tribe-events-event-body,
.datepicker-months {
   margin-top: 0;
}

#tribe-events-content .tribe-event-description {
   font-size: $font-xl;

   a {
      text-decoration: underline;
   }
}

.tribe-events-event-thumb {
   margin-top: 10px;
}

#tribe-events-content .tribe-events-calendar div[id*='tribe-events-event-'] h3.tribe-events-month-event-title {
   font-family: $font-secondary;
}

.tribe-event-duration {
   font-weight: bold;
   font-size: $font-xl;
}

.tribe-events-tooltip .entry-title {
   font-size: $font-xxxl;
}

.single-tribe_events #tribe-events-header {
   margin-bottom: 5em;
}

.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'],
.tribe-events-calendar td.tribe-events-present div[id*='tribe-events-daynum-'] > a {
   background-color: color(brown);
}

.single-tribe_events .tribe-events-event-meta dl {
   padding-left: 0;
}

.single-tribe_events #tribe-events-content .tribe-events-event-meta dt {
   font-size: 16px;
   padding-bottom: 5px;
   margin-top: 27px;
}

#tribe-events-content .tribe-events-single-section a {
   text-decoration: underline;
}

#tribe-events-content {
   margin-bottom: 100px;
}

#tribe-events-content-wrapper {
   margin-top: 4em;
}

.tribe-events-shortcode.tribe-events-view-wrapper.view-list {
   #tribe-bar-form,
   #tribe-events-header,
   #tribe-events-footer,
   .tribe-events-title-bar,
   .tribe-events-ical.tribe-events-button,
   .tribe-events-event-image,
   .tribe-events-event-cost,
   .tribe-events-venue-details,
   .tribe-events-list-event-description p {
      display: none;
   }
}

.tribe-events-event-meta-custom {
   margin: 1em 0 0 0;

   h3 {
      text-transform: initial;
      margin-bottom: 10px;
   }

   p {
      margin: 0;
   }

   p + h3 {
      margin-top: 1em;
   }
}

.tribe-mini-calendar-event .list-info .tribe-events-event-meta-custom p {
   display: block;
}

.bereavement-services .tribe-mini-calendar-event .list-info h2 {
   font-size: 18px;
   margin-bottom: 10px;
   display: block;
   color: #686868;
   text-transform: initial;
   line-height: 1.4;
}

.bereavement-services .tribe-mini-calendar-event .list-info h2 a {
   text-decoration: none;
}

.bereavement-services .type-tribe_events + .tribe-events-widget-link,
.bereavement-services .tribe-mini-calendar-event .list-date {
   display: none;
}

.bereavement-services div.type-tribe_events:last-of-type {
   margin-bottom: 3em;
}

.bereavement-services .tribe-mini-calendar-event {
   border-bottom: 1px solid #cdcdcd;
   margin-bottom: 5px;
   overflow: hidden;
   padding-bottom: 38px;
   width: 100%;
}

.single.single-tribe_events .tribe-events-event-meta-custom {
   display: none;
}
