.is-visible {
  visibility: visible !important;
  opacity: 1 !important;
  display: block !important;
}

.no-pseudo:after,
.no-pseudo:before {
  content:none;
}

.is-hidden {
  display: none;
  opacity: 0;
}

.is-hidden-by-default {
  visibility: hidden;
}

.is-gone {
  visibility: hidden;
}

.is-fadeout {
  opacity: 0;
}

.is-circle {
  border-radius: 50%;
  display: block;
  -webkit-backface-visibility: hidden;
}

.is-foreground {
  z-index: 9999999999999999999999999;
}

.is-align-right {
  text-align: right;
}

.is-closer {
  transition: transform 0.2s ease;

  &:hover {
    transform: scale(1.02);
    z-index: 2;
  }

}

.is-loading {
  opacity: $opacity-soft;
}

.is-spinner {
  position: relative;

}


.is-spinner-sm {
  position: relative;
}



// Loading spinner
.spinner {
  font-size: $font-xxxl;
  animation-duration: 0.5;
  z-index: 99999999999999;
  position: absolute;
  top: -6.3%;
  left: 45.8%;
  transform-origin: 50% 48%;
}


@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
