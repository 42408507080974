.component {
  margin: $space-l 0;
  position: relative;
}

.components {

  > section:last-child {
    margin-bottom: $space-l*5;
  }

}

.component-last {
  margin-bottom: $space-l*5;

  @include respond-to(600, 'max') {
    //margin-bottom: 0;
  }

}
