.nav {
  margin: 0;

  > .menu-item {

    a {
      font-family: $font-primary;
      text-transform: uppercase;
      letter-spacing: 2px;
      padding: $space-s $space-s*2;
      z-index: 999999;
      font-weight: 400;
    }

    > a {
      position: relative;
      top: 10px;
      font-weight: bold;
    }

    &.btn-menu > a {
      position: relative;
      top: 4px;
      font-size: 14px;

      &:hover {
        color: white;
      }

    }

    .menu-item a {
      text-transform: none;
      letter-spacing: 0;
    }

    &.menu-call {
      margin: 0 1em;
    }

  }

}


#menu-top-nav {
  margin-right: -50px;
}


.is-active-mobile-menu #menu-mobile-navigation {
  display: block;
  position: absolute;
  background-color: color(primary);
  width: 100%;
  margin: 0;
  padding: 0;
  left: 0;
  top: 70px;
  padding: $space-xl;
  z-index: -1;
}

.header-shrink .is-active-mobile-menu #menu-mobile-navigation {
  top: 60px;
}

#menu-mobile-navigation {
  .menu-item a {
    display: block;
    width: 100%;
  }
}


.nav,
.sub-menu {
  list-style: none;
}

.sub-menu {
  padding-left: $space-s;
  background-color: color(primary);
}

.menu-item-has-children {

  .sub-menu {
    display: none;
    position: absolute;
    margin-top: 0;
    padding: $space-m*2.7 0 $space-m 0;
    min-width: 250px;
    opacity: 0.8;
    z-index: 2;

    a {
      font-size: $font-s;
      padding: $space-xxs $space-m*2;
      display: block;
    }

  }

  &.btn-menu {

    .sub-menu {
      padding-top: 25px;

      a {
        background-color: transparent;
        color: color(brown);
      }

    }

  }

  .svg-inline--fa {
    margin-right: $space-m;

    @include respond-to(1000, 'min') {
      display: none;
    }

  }

  &.is-active {

    .sub-menu {
      display: block;
    }

  }

}

.nav-primary {
  padding: 0;

  @include respond-to(1000, 'max') {
    display: none;
  }

}

.toggle-mobile-menu-ham {
  padding: 10px;
  opacity: 1;
  overflow: hidden;
	transition: opacity ease 1.3s;

  &:hover {
    cursor: pointer;
  }

}

.toggle-mobile-menu {
  position: absolute;
  margin: 0;
  right: 10px;
  top: calc(50% - 27px);
  max-width: 70px;

  &.toggle-mobile-menu-active {
    display: none;
    opacity: 0;

    + .toggle-mobile-menu-close {
      display: block;
      opacity: 1;
    }

  }

}

.toggle-mobile-menu-close {
  display: none;
  padding: 10px;
  opacity: 0;
  transition: opacity ease 1.3s;

  &:hover {
    cursor: pointer;
  }

}


.nav-mobile {
  display: none;

  .nav > .menu-item > a {
    padding-left: 0;
    padding-right: 0;
  }

  .menu-item-has-children {

    .sub-menu.is-active {
      display: block;
      position: static;
      padding-top: 0;
    }

  }

  @include respond-to(1000, 'max') {
    display: block;

    .nav {
      display: none;
    }

  }

}

.menu-item {

  a {
    color: color(tertiary-off);
    font-size: $font-xs;
    padding: 6px 25px;
    text-decoration: none;

    &:hover {
      color: color(brown);
    }

  }

}

.header {
  .menu-item {
    margin: 0;
  }
}
