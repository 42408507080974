//
// Grid, nice n simple
//
.row,
.widget .row {
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
  list-style: none;
  flex: 0 1 auto;
  // margin: -#{$space-l} 0 $space-l -#{$space-l};
  margin-top: 0;
  padding: 0;
  // margin-right: calc(-#{$space-l} / 2);
  // margin-left: calc(-#{$space-l} / 2);
}

.col {
  display: flex;
  flex-direction: column;
  flex: 0 0 auto;
  flex-wrap: wrap;
  list-style: none;
  margin: 0;
  min-width: 0;
  min-height: 1px;
}


.contain {
  max-width: $max-width-xlarge;
  padding: 0 $space-xxs;
  margin: 0 auto;
  padding: 0 $space-l;

  @include siteMaxWidth();

  @include respond-to(1200, 'max') {
    // padding: 0 $space-m;
  }

}

.contain-narrow {
  max-width: $content-width-narrow;
  margin: 0 auto;

  @include respond-to(1000, 'max') {
    max-width: 700px;
  }

  @include respond-to(600, 'max') {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }
}

.contain-extra-narrow {
  max-width: $content-width-extra-narrow;
  margin: 0 auto;

  @include respond-to(1000, 'max') {
    max-width: 700px;
  }

  @include respond-to(600, 'max') {
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
  }

}


/*

Columns (as a proportion)
E.g., 2 === one half, 3 === one third, 4 === one fourth, etc

*/
// .col-1 {
//   @include rowMachine($cols-1);
// }
//
// .col-2 {
//   @include rowMachine($cols-2);
//
//   @include respond-to(600, 'max') {
//     @include rowMachine($cols-1);
//   }
// }
//

.col-3 {
  // @include rowMachine($cols-3);
  max-width: 33.3%;

  // @include respond-to(900, 'max') {
  //   @include rowMachine($cols-2);
  // }
  //
  // @include respond-to(600, 'max') {
  //   @include rowMachine($cols-1);
  // }
}


.col-4 {
  max-width: 25%;

  // @include rowMachine($cols-4);
  //
  // @include respond-to(800, 'max') {
  //   @include rowMachine($cols-2);
  // }
  //
  // @include respond-to(500, 'max') {
  //   @include rowMachine($cols-1);
  // }
}

.col-auto {
  flex: 1;
}

.col-reverse {
  flex-direction: column-reverse;
}

.col-center {
  align-items: center;
}

.col-left {
  align-self: flex-start;
}

.col-end {
  align-items: flex-end;
}

//
// Ordering
//
.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

//
// Row aignment
//
.row-left {
  justify-content: flex-start;
}

.row-right {
  justify-content: flex-end;
}

.row-center {
  justify-content: center;
  margin: 0 auto;
}

.row-space-between {
  justify-content: space-between;

  // &:after {
  //   content: "";
  //   flex: auto;
  // }
}

.row-space-around {
  justify-content: space-around;
}

.row-reverse {
  flex-direction: row-reverse;
}

//
// Spacing
//
.fit {
  flex-grow: 1;
}

.fill {
  flex: 1;
  min-width: 0;
}

.contained {
  flex: none;
}


[class*=col-] {
  margin-top: 0;
}

[class*=col-],
[class*=col_],
[class~=col] {
  padding: 0 .5rem .5rem;

  > .col-content > .heading h1,
  > .col-content > .heading h2,
  > .col-content > .heading h3,
  > .col-content > .heading h4,
  > .col-content > .heading h5,
  > .col-content > .heading h6 {
    margin-top: 0;
  }

}

.l-center {
  text-align: center;
}

.col-1,
.col-2,
.col-3,
.col-4,
.col-5,
.col-6,
.col-7,
.col-8,
.col-9,
.col-10,
.col-11,
.col-12 {

  @include respond-to(800, 'max') {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

}


.grid.masonry-container {
  justify-content: space-between;

  .masonry-block {
    margin-bottom: $space-xxxs;
  }
}


.col-1 {
  flex: 100%;
  max-width: 100%;
}

.col-2 {
  flex: calc(100% / 2);
  max-width: calc(100% / 2 - #{$space-xxxxxxs});

  @include respond-to(800, 'max') {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

}

.col-3 {
  flex: calc(100% / 3);
  max-width: calc(100% / 3 - #{$space-xxxxxxs});

  @include respond-to(800, 'max') {
    width: 100%;
    flex-basis: 100%;
    max-width: 100%;
  }

}

.col-4 {
  flex: calc(100% / 4 - #{$space-xxxxxxs});
  max-width: calc(100% / 4 - #{$space-xxxxxxs});

  @include respond-to(1000, 'max') {
    flex: calc(100% / 2 - #{$space-xxxxxxs});
    max-width: calc(100% / 2 - #{$space-xxxxxxs});
  }

}

.col-5 {
  flex: calc(100% / 5 - #{$space-xxxxxxs});
  max-width: calc(100% / 5 - #{$space-xxxxxxs});
}

.col-6 {
  flex: calc(100% / 6 - #{$space-xxxxxxs});
  max-width: calc(100% / 6 - #{$space-xxxxxxs});

  @include respond-to(1000, 'max') {
    flex: calc(100% / 2 - #{$space-xxxxxxs});
    max-width: calc(100% / 2 - #{$space-xxxxxxs});
  }

}

.col-7 {
  flex: calc(100% / 7 - #{$space-xxxxxxs});
  max-width: calc(100% / 7 - #{$space-xxxxxxs});
}

.col-8 {
  flex: calc(100% / 8 - #{$space-xxxxxxs});
  max-width: calc(100% / 8 - #{$space-xxxxxxs});

  @include respond-to(1000, 'max') {
    flex: calc(100% / 4 - #{$space-xxxxxxs});
    max-width: calc(100% / 4 - #{$space-xxxxxxs});
  }

}

.col-9 {
  flex: calc(100% / 9 - #{$space-xxxxxxs});
  max-width: calc(100% / 9 - #{$space-xxxxxxs});
}

.col-10 {
  flex: calc(100% / 10 - #{$space-xxxxxxs});
  max-width: calc(100% / 10 - #{$space-xxxxxxs});

  @include respond-to(1000, 'max') {
    flex: calc(100% / 5 - #{$space-xxxxxxs});
    max-width: calc(100% / 5 - #{$space-xxxxxxs});
  }

}

.col-11 {
  flex: calc(100% / 11 - #{$space-xxxxxxs});
  max-width: calc(100% / 11 - #{$space-xxxxxxs});
}

.col-12 {
  flex: calc(100% / 12);
  max-width: calc(100% / 12);

  @include respond-to(1000, 'max') {
    flex: calc(100% / 4 - #{$space-xxxxxxs});
    max-width: calc(100% / 4 - #{$space-xxxxxxs});
  }

}
