textarea,
input,
select,
input,
#give-recurring-form .form-row input[type=email].required,
#give-recurring-form .form-row input[type=password].required,
#give-recurring-form .form-row input[type=tel].required,
#give-recurring-form .form-row input[type=text].required,
#give-recurring-form .form-row input[type=url].required,
#give-recurring-form .form-row select.required,
#give-recurring-form .form-row textarea.required,
form.give-form .form-row input[type=email].required,
form.give-form .form-row input[type=password].required,
form.give-form .form-row input[type=tel].required,
form.give-form .form-row input[type=text].required,
form.give-form .form-row input[type=url].required,
form.give-form .form-row select.required,
form.give-form .form-row textarea.required,
form[id*=give-form] .form-row input[type=email].required,
form[id*=give-form] .form-row input[type=password].required,
form[id*=give-form] .form-row input[type=tel].required,
form[id*=give-form] .form-row input[type=text].required,
form[id*=give-form] .form-row input[type=url].required,
form[id*=give-form] .form-row select.required,
form[id*=give-form] .form-row textarea.required,
form[id*=give-form] input[type="text"].give-input,
form[id*=give-form] input[type="text"].textfield {
  font-size: $font-m;
  width: 100%;
  display: block;
  padding: $form-padding;
  font-weight: 100;
  font-family: $font-primary;
  line-height: 1;
  border: 1px solid #ccc;
  height: auto;
  border-radius: 0;
  color: color(base);
  background-color: #fdfdfd;
}

select,
form.give-form .form-row select.required,
form[id*=give-form] .form-row select.required,
.select2-container,
.select2-selection--single,
.select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__arrow,
.select2-container--default .select2-selection--single .select2-selection__rendered,
.select2-container--default .select2-selection--single {
  height: 42px;
}

.select2-container--default .select2-selection--single .select2-selection__rendered {
  line-height: 2.5;
}

.select2-container--default .select2-selection--single .select2-selection__arrow {
  right: 10px;
}

form[id*=give-form] #give-ffm-section {
  margin-top: $space-m * 2;
}

[id*=give-form] fieldset {
  margin-bottom: $space-m*1.5;

  legend {
    font-size: $font-m;
    font-family: $font-primary;
  }

}

label,
.give-label,
.ffm-radio-field label,
.give-donation-amount {
  text-transform: initial;
  font-family: $font-primary;
}

.ffm-fields.ffm-radio-field {
  display: flex;
  margin-top: 0;

  label {
    margin: 0;

    &:first-child {
      margin-right: 1em;
    }
  }

}

.give-total-wrap,
.select2-selection__arrow {
  margin-top: 0;
}


.give-custom-amount-text {
  margin-bottom: $space-m*2;
  font-weight: bold;
  line-height: 1;
  font-size: 80%;
}

.give-donation-total-label,
.give-final-total-amount {
  font-size: 22px;
}

.post-password-form {

  input[name="Submit"] {
    width: auto;
  }

}
