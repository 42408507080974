// Breakpoints
@mixin respond-to($breakpoint, $size) {
  @if map-has-key($breakpoints, $breakpoint) {

    @if($size == 'max') {
      @media (max-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    } @else {
      @media (min-width: #{map-get($breakpoints, $breakpoint)}) {
        @content;
      }
    }

  }

  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
        + "Please make sure it is defined in `$breakpoints` map.";
  }
}


@mixin rowMachine($cols) {

  $colWidth:          calc(100% / #{$cols});
  $gutterWidth:      calc((#{$space-l} / 2) * 2);
  $gutterWidthRemainder: calc(#{$gutterWidth} + (#{$gutterWidth} / (#{$cols} - 1)));


  @if($cols == 1) {
    flex: 0 0 auto;
    max-width: 100%;
    width: 100%;

  } @else {

    // Grow, Shrink, Basis
    flex: 0 0 calc(#{$colWidth} - #{$gutterWidth});
    max-width: calc(#{$colWidth} - #{$gutterWidth});
    width: calc(#{$colWidth} - #{$gutterWidth});

    margin-right: $gutterWidthRemainder;

  }

}

@mixin siteMaxWidth() {
  max-width: $max-width-xlarge;
}

@mixin smoothTransition() {
  transition: all ease 0.5s;
}
