/*

WooCommerce

*/
.woocommerce .quantity .qty,
.opc_order_review .product-quantity,
.opc_order_review .product-details {
  margin: 0 auto;
}

.opc_order_review .product-details {
  text-align: left;
  padding-left: 1em;
  max-width: 400px;

  @include respond-to(700, 'max') {
    max-width: 300px;
  }

}

.cart-subtotal,
.order-total {
  th {
    text-align: right;
  }
}


#order_review .woocommerce-checkout-review-order-table {

  @include respond-to(500, 'max') {
    display: block;
    width: 100%;

    * {
      // display: block;
      // width: 100%;
    }

    .product-remove {
      width: 100%;
      display: block;
      float: none;
      margin: 0;
      text-align: center;

      .remove {
        display: block;
        margin: 0 auto;
      }

    }

    thead {
      display: none;
    }

    tbody,
    tfoot,
    .cart_item {
      display: flex;
      flex-direction: column;
      margin-top: 0;
    }

    tfoot {
      padding-bottom: 1.7em;
    }

    .cart-subtotal,
    .order-total {
      margin: 0;
      border: none;

      td {
        border: none;
      }

      th {
        display: block;
        width: 100%;
        text-align: center;
        margin-top: 2em;
        border: none;
      }

    }

    .product-details {
      text-align: center;
      display: block;
      width: 100%;
      padding: 0;
    }

    .product-quantity {
      display: flex;
      width: 100%;
      justify-content: center;
      margin-top: 1em;
    }

    .product-total {
      border-top: none;
      border-bottom: 1px solid #ddd;
      padding-bottom: 2em;
    }

  }

}






.woocommerce table.shop_table .product-item td:last-child {

  @include respond-to(500, 'max') {
    padding-bottom: 2em;
  }

}

.woocommerce table.shop_table td,
#order_review .opc_cart_item td.product-name {
  // border: 1px solid rgba(0,0,0,.1);
}

.woocommerce table.shop_table td {
  text-align: center;

  @include respond-to(500, 'max') {
    width: 100%;
    max-width: 100%;
    display: block;
    margin-top: 0;
  }

}

.woocommerce .product-thumbnail a img {

  @include respond-to(500, 'max') {
    max-width: 100px;
    margin: 0 auto;
  }

}

.woocommerce .product-thumbnail a {
  display: block;
  padding: 10px;
  max-width: 70%;
  margin: 0 auto;

  img {
    position: relative;
    top: 7px;
    left: 10px;
  }

}

.woocommerce form .form-row {
  margin: 0 0 18px;
}

.place-order {
  background-color: white;
  font-size: 20px;
}

.woocommerce-terms-and-conditions-wrapper {
  margin-top: 0;
}

#add_payment_method #payment ul.payment_methods,
.woocommerce-cart #payment ul.payment_methods,
.woocommerce-checkout #payment ul.payment_methods,
.woocommerce-checkout #payment div.form-row {
  background-color: white;
  padding-left: 0;
  padding-right: 0;
  border-bottom: none;
}

form.woocommerce-checkout {
  margin-bottom: 7em;
}

#order_review .opc_cart_item td.product-name {
  padding: 2em;
}


.product-item {
  .product-thumbnail,
  .product-name,
  .product-price {
    border-right: 1px solid rgba(0,0,0,.1);
  }
}

.woocommerce table.shop_table td.product-name,
.woocommerce table.shop_table td.product-price,
.woocommerce table.shop_table td.product-quantity {
  @include respond-to(500, 'max') {
    border-top: none;
  }
}


/*

WordPress Default Styles

*/
img.alignright { float:right; margin:0 0 1em 1em }
img.alignleft { float:left; margin:0 1em 1em 0 }
img.aligncenter {display: block; margin-left: auto; margin-right: auto }
a img.alignright { float:right; margin:0 0 1em 1em }
a img.alignleft { float:left; margin:0 1em 1em 0 }
a img.aligncenter { display: block; margin-left: auto; margin-right: auto }

img.size-full {
  width: 100%;

  @include respond-to(600, 'max') {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

}




.flexboxtweener {

  .nav-primary {
    position: relative;
    top: -40px;
  }

  .header-shrink .nav-primary {
    top: -47px;
  }

  .header.row,
  .header-container,
  .header-wrapper {
    display: block;
  }

  .component-generic {
    .row {
      display: block;

      [class*="col"] {
        float: left;
      }

    }
  }

  .row-center {
    display: block;
    margin: 0 auto;
  }

  .col,
  .l-col {
    display: block;
    width: 100%;
    flex: none;
  }

  .nav-mobile {
    display: none;
  }

  .l-content-main {
    float: left;
    width: 100%;
    max-width: 100%;
    flex: none;
    height: auto;

    &:after {
      content:" ";
      display:block;
      clear:both;
    }
  }

  .l-component,
  .l-wrapper .component,
  .l-wrapper :only-of-type:last-of-type.component {
    flex: none;
    display: block;
    width: 100%;
    height: auto;

    &:after {
      content:" ";
      display:block;
      clear:both;
    }
  }

  .l-wrapper,
  .l-content,
  .l-content-main {
    display: block !important;
    width: 100%;
    flex: none;

    &:after {
      content:" ";
      display:block;
      clear:both;
    }
  }

  [class*="l-box"] {
    display: block;
    flex: none;
  }

  // .row {
  //
  //   [class*="l-col"] {
  //     float: left;
  //   }
  //
  // }

  .l-col-2 {
    width: calc(100%/2);
    max-width: calc(100%/2);

    @include respond-to(600, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

  .l-col-3 {
    width: calc(100%/3);
    max-width: calc(100%/3);

    @include respond-to(900, 'max') {
      width: calc(100%/2);
      max-width: calc(100%/2);
    }

    @include respond-to(600, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

  .l-col-4 {
    width: calc(100%/4);
    max-width: calc(100%/4);

    @include respond-to(800, 'max') {
      width: calc(100%/2);
      max-width: calc(100%/2);
    }

    @include respond-to(500, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

  .l-col-5 {
    width: calc(100%/5);
    max-width: calc(100%/5);

    @include respond-to(800, 'max') {
      width: calc(100%/2);
      max-width: calc(100%/2);
    }

    @include respond-to(500, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

  .l-col-6 {
    width: calc(100%/6);
    max-width: calc(100%/6);

    @include respond-to(1000, 'max') {
      width: calc(100%/4);
      max-width: calc(100%/4);
    }

    @include respond-to(800, 'max') {
      width: calc(100%/3);
      max-width: calc(100%/3);
    }

    @include respond-to(700, 'max') {
      width: calc(100%/2);
      max-width: calc(100%/2);
    }

    @include respond-to(500, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

  .l-col-7 {
    width: calc(100%/7);
    max-width: calc(100%/7);

    @include respond-to(1100, 'max') {
      width: calc(100%/5);
      max-width: calc(100%/5);
    }

    @include respond-to(900, 'max') {
      width: calc(100%/4);
      max-width: calc(100%/4);
    }

    @include respond-to(700, 'max') {
      width: calc(100%/3);
      max-width: calc(100%/3);
    }

    @include respond-to(600, 'max') {
      width: calc(100%/2);
      max-width: calc(100%/2);
    }

    @include respond-to(500, 'max') {
      width: calc(100%/1);
      max-width: calc(100%/1);
    }
  }

}
