$colors: (
  black: #000000,
  white: #FFFFFF,
  off-white: #f2f2f2,
  base: #686868,
  primary: #eae5df,
  tertiary: #685d56,
  tertiary-off: #887f6f,
  msg-success: #cfffe0,
  grey-dark: #4a4a4a,
  grey-medium: #a1a1a1,
  brown: #685d56,
  msg-error: rgb(255, 184, 184)
);
