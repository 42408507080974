.widget {
  width: 25;
  max-width: 25%;
  flex: 0 0 25;
  margin-top: 0;

  input {
    border: none !important;
  }

  @include respond-to(1100, 'max') {
    margin-bottom: $space-xxl;
    width: 50%;
    max-width: 50%;
    flex: 0 0 50%;
    padding: $space-xl;

    .nav-primary {
      display: block;
    }

  }

  @include respond-to(600, 'max') {
    width: 100%;
    max-width: 100%;
    flex: 0 0 100%;
  }


}

.widget-contact-email {
  display: block;
  margin-top: $space-m;
  text-decoration: none;

  &:hover {
    color: color(brown);
  }
}

.widget-contact-email,
.widget-contact-phone,
.widget-description {
  color: color(grey-medium);
  font-size: 13px;
  margin: $space-s*2 0;
}

.widget-support {

  .so-widget-image {
    max-width: 100px;
  }

  .btn-donate {
    margin: 2em 0;
    font-size: 1.2em;
    background-color: color(brown);
    color: white;

    &:hover {
      opacity: $opacity-soft;
    }

  }

}

.widget-contact-address {
  margin-bottom: 0;
  margin-top: 0;
  color: color(grey-medium);
  font-size: 13px;

  .svg-inline--fa {
    margin-right: 15px;
    position: relative;
    top: 5px;
    left: 2px;
  }

}

.widget-contact-address-line-2 {
  margin-left: 29px;
}

.widget-heading,
.null-instagram-feed h3 {
  font-size: $font-l;
  color: color(base);
  font-weight: bold;
  padding-bottom: $space-xxs;
  margin-bottom: $space-s;
  margin-top: 0;
  letter-spacing: 1px;
  line-height: 1;
  text-transform: uppercase;

  @include respond-to(900, 'max') {
    font-size: $font-xxl;
  }

}

.widget-social-icon {

  a {
    font-size: 24px;
    padding-right: $space-m;
    color: color(grey-medium);
    display: inline-block;
  }

}

.widget .widget-social-icons {
  margin: $space-xl*2 0;
}

.widget-contact {

  .menu {
    padding: 0;
    list-style: none;
  }

  .menu-item {
    margin: 0;

    a {
      padding-left: 0;
      margin: 0;
      padding-top: 0;
    }
  }

  .svg-inline--fa {
    font-size: 20px;
    opacity: 0.7;
    margin-right: 10px;
    position: relative;
    top: 5px;
  }

}

.widget-services {
  width: 23%;

  p {
    line-height: 1;

    br {
      display: none;
    }

  }

  a {
    color: color(grey-medium);
    padding: 5px 0;
    font-size: 13px;
    display: block;
    margin-top: 0;
    text-decoration: none;

    &:hover {
      color: color(brown);
    }

  }

}

.widget-description {
  margin-top: 0;
}

.instagram-pics {
  padding: 0;

  + .clear {
    display: none;
  }

  a:hover {
    opacity: $opacity-soft;
  }

}

.widget .instagram-pics li {
  width: 31.3%;
  margin-right: 2%;
  display: inline-block;
  padding: 0;
  border: none;
}

.widget-social-link {
  &:hover {
    color: color(brown);
  }
}

.widget-contact-address-line-1 {
  margin: 0;
}
