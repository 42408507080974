.component-generic {
  margin: $space-l*3 0;

  .col-content {

    h1, h2, h3, h4, h5, h6 {
      &:first-of-type {
        margin-top: 0;
      }
    }

    p {

      &:first-of-type {
        margin-top: 0;
      }

      &:last-of-type {
        margin-bottom: 0;
      }
    }

  }

  .grid {
    @include respond-to(500, 'max') {
      flex-direction: column;
    }
  }

}
