.btn,
a.um-button,
input[type='submit'],
button,
.button,
.nav-previous a,
.nav-next a,
#give-purchase-button,
.woocommerce #respond input#submit,
.woocommerce a.button,
.woocommerce button.button,
.woocommerce input.button,
#tribe-events .tribe-events-button,
.tribe-events-button,
.tribe-events-back a,
#tribe-events .tribe-events-button:hover,
.tribe-events-read-more,
#tribe-events #tribe-events-content .tribe-events-button,
.btn-menu a,
.blog .main .entry-summary > p a {
   padding: $space-s $space-m * 2;
   // color: color(brown);
   // background-color: color(white);
   text-decoration: none;
   text-transform: capitalize;
   font-size: $font-m;
   font-family: $font-primary;
   display: inline-block;
   border-radius: 0;
   position: relative;
   line-height: 1;
   font-weight: 400;
   box-shadow: none;
   outline: none;
   opacity: 1;
   transition: none;
   // border: 2px solid color(brown);

   background-color: color(brown);
   color: white;

   // &:hover {
   //
   // }

   @include smoothTransition();

   &:hover {
      opacity: 0.6;

      &.is-disabled {
         opacity: 0.6;
      }
   }

   &:focus {
      border: none;
      outline: none;
      background-color: color(brown);
      color: white;
   }
}

.link-dark {
   color: color(brown);
}

.btn-lg {
   font-size: $font-xl;
}

.btn-secondary,
.page.woocommerce #respond input#submit,
.page.woocommerce a.button,
.page.woocommerce button.button,
.page.woocommerce input.button {
   background: color(grey-xxlight);
   color: color(base);

   &:hover {
      background-color: color(grey-dark);
      color: white;
      cursor: pointer;

      &.is-disabled {
         background-color: color(grey-dark);
      }
   }
}

.btn-tertiary {
   background-color: rgb(234, 229, 223);
   border: none;
   color: color(base);

   &:hover {
      background-color: #ccc6be;
      color: color(base);
      opacity: 1;
   }

   &:focus {
      background-color: rgb(234, 229, 223);
      color: color(base);
      opacity: 1;
   }
}

#give-purchase-button,
.page.woocommerce #payment #place_order,
.page.woocommerce-page #payment #place_order,
.page.woocommerce #respond input#submit.alt,
.page.woocommerce a.button.alt,
.page.woocommerce button.button.alt,
.page.woocommerce input.button.alt,
#tribe-bar-form .tribe-bar-submit input[type='submit'] {
   background-color: color(brown);
   color: white;

   &:hover {
      opacity: 0.5;
   }
}

.tribe-events-list {
   .tribe-events-list-separator-month {
      padding: 12px 16px;
   }

   .tribe-events-read-more {
      font-size: 16px;
   }

   .tribe-events-list-event-title .tribe-event-url {
      font-size: 18px;
      margin-bottom: 10px;
      display: block;
   }

   .tribe-events-ical {
      display: none !important;
   }

   // .tribe-events-loop {
   //   display: none;
   // }
}
