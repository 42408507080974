.post {
   margin-bottom: $space-xl * 3;
   padding-bottom: $space-xl * 2;
   border-bottom: 1px solid color(grey-xxlight);
   max-width: 920px;
   margin-left: auto;
   margin-right: auto;
}

.heateor_sss_sharing_title {
   font-family: $font-primary;
}

.byline,
.updated {
   margin-top: 0;
   font-family: $font-primary;
   font-weight: normal;
   font-size: $font-xl;
}

.single-post {
   .entry-title {
      margin-bottom: $space-l;
      font-size: 42px;
   }

   .type-post {
      .entry-title {
         margin-bottom: 10px;
      }
   }

   .byline.author {
      margin-bottom: 0;
   }

   .entry-content {
      max-width: 100%;

      p,
      li {
         font-size: 16px;
      }

      p {
         margin: 2em 0;
      }

      li {
         margin-top: 0;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
         font-size: 1.6em;
         margin-bottom: 1.2em;
         margin-top: 2em;
         text-transform: initial;
         color: color(base);
         font-weight: 400;
         line-height: 1.4;
      }
   }

   blockquote {
      text-transform: uppercase;
      color: color(primary);
      border: none;
      font-style: normal;
      margin: 2.5em 0 2.5em 1.5em;
      position: relative;
      width: 100%;
      left: 0;
      font-size: 1.3em;
      font-family: $font-primary;
      padding-left: 3em;
   }
}

.byline {
   margin-right: 2em;

   span {
      text-decoration: none;
      font-weight: normal;
      position: relative;
      text-transform: capitalize;

      &:after {
         content: '';
         display: block;
         background: black;
         width: 18px;
         height: 1px;
         position: absolute;
         right: -25px;
         top: 11px;
      }
   }
}

.posts-navigation {
   display: flex;
   justify-content: center;
   margin-bottom: 4em;
   text-align: center;

   .screen-reader-text {
      margin-right: $space-m;
      display: none;
   }

   .screen-reader-text,
   .nav-links {
      margin-top: 0;
   }

   .nav-links {
      display: flex;
   }

   .nav-previous,
   .nav-next {
      margin-top: 0;
      margin-left: 10px;
      margin-right: 10px;
   }
}

.feat-img-wrapper {
   max-width: 100%;
   margin: 1em 0;
   display: block;

   img {
      max-width: 100%;
      display: block;
   }
}

.entry-title {
   text-decoration: none;
   font-size: $font-xl * 1.7;
   font-weight: 400;
   text-transform: initial;

   a {
      text-decoration: none;
      font-weight: bold;
   }
}

.entry-sub-title {
   text-transform: capitalize;
   color: color(base);
   margin-top: 0;
   margin-bottom: $space-l * 2;
   font-weight: 100;
}

.entry-summary {
   max-width: 800px;
   margin: 0 auto;
   font-family: $font-primary;
   font-size: $font-xl;
}

.entry-content {
   max-width: $content-width-narrow;
   margin: 0 auto;
   line-height: 1.8;
   max-width: 100%;
}

.faqs.type-faqs {
   header {
      display: none;
   }
}

.entry-category {
   margin: 0 7px;
   padding: 5px 10px;
   text-transform: uppercase;
   font-family: $font-primary;
   display: inline-block;
   background: color(grey-xxlight);
   color: color(grey-dark);
}

.categories-name {
   font-family: $font-primary;
   font-size: 15px;
   font-weight: bold;
   margin-right: 0.7em;
}

.entry-categories {
   margin-top: 1em;
   margin-bottom: 2em;
   // display: none;

   a {
      display: inline-block;
      text-decoration: underline;
      text-transform: capitalize;
      padding: 0 10px;
      margin: 0;
      font-size: 15px;
   }

   a:first-child {
      margin-left: 0;
   }
}
