.header {
  margin-top: 0;
  background-color: color(primary);
  padding: 0;
  position: fixed;
  width: 100%;
  top: 0;
  height: 120px;
  transition: all 0.4s;
  z-index: 999;
  padding: 3em 0 1em 0;

  @include respond-to(1000, 'max') {
    padding-top: 1em;
    height: 90px;
  }

}

#menu-top-nav {
  align-items: center;
}

.header-wrapper {
  margin: 0;
  display: block;
}

.is-header-shrink {

  .top-nav {
    transition: all 0.4s;
    opacity: 0;
    transform: translateY(-45px);
  }

  .nav-primary {
    transition: all 0.4s;
    transform: translateY(-5px);
  }

}

.top-nav {
  position: fixed;
  top: 0;
  right: calc((100vw - 1140px) / 1.8);
  width: 100%;
  z-index: 9999;
  transition: all 0.4s;
  opacity: 1;
  transform: translateY(0px);

  @include respond-to(1200, 'max') {
    right: 23px;
  }

  @include respond-to(1000, 'max') {
    display: none;
  }

  .nav > .menu-item {

    &.link {
      margin-top: 0;
      position: relative;
      top: -3px;

      a {
        color: color(brown);
        text-decoration: underline;
        padding-left: 0;
      }

    }

    a {
      margin-top: 0;
      color: white;
      text-transform: capitalize;
      letter-spacing: 0;
      z-index: 999999;
      font-weight: 400;
      font-size: 0.9em;
      color: white;
      padding: .72645em 1.6em;
      display: block;
    }

  }

  .btn {
    margin-top: 0;
    color: white;
    text-transform: capitalize;
    letter-spacing: 0;
    z-index: 999999;
    font-weight: 400;
    padding: 0;
    margin-left: 1em;
    position: relative;
    top: -4px;
  }

}

.header-shrink {
  position: fixed;
  right: 0;
  left: 0;
  z-index: 999;
  padding: 0;
  transition: all 0.4s ease;
  height: 70px;

  .logo-primary {
    width: 140px;
    padding: 10px;
  }

}

.header-container {
  align-items: center;
  position: relative;
}

.page-header-image {
  min-height: 335px;
  margin: 0;
  width: 100%;
  line-height: 1;
  background-size: cover;
  background-position: 50% 50%;
  position: relative;

  .page-heading {
    position: absolute;
    top: 0;
    margin-top: 0;
    z-index: 99;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: white;
    font-family: $font-primary;
    font-size: $font-xxl*2;
    padding: 0;
    margin: 0;
  }

  @include respond-to(500, 'max') {
    min-height: 180px;
  }

}

.logo-wrapper {
  padding: 0;

  @include respond-to(1000, 'max') {
    flex: 0 0 auto;
    max-width: auto;
    width: auto;
  }

}

.logo-primary {
  height: auto;
  width: 180px;
  transition: all ease 0.5s;

  @include respond-to(1000, 'max') {
    max-width: 150px;
  }

}

.page-heading {
  text-align: center;
  text-transform: uppercase;
  margin: $space-l*2 0 $space-xl 0;
  font-weight: bold;
  letter-spacing: 2px;
  padding-bottom: $space-xl;

  @include respond-to(800, 'max') {
    margin-top: $space-l;
  }

}
